import React, { useEffect, useState } from "react";
import "./Cupon.css";
import { useHistory, useLocation } from "react-router-dom";
import { publicRequest, userRequest } from "../../requestMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleAssignedCoupon = () => {
  const location = useLocation();
  const cuponId = location.pathname.split("/")[2];
  const [cupon_code, setCuponCode] = useState("");
  const [cupon_amount, setCuponAmount] = useState("");
  const [product_no, setProductNo] = useState("");
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [formcuponcode, setFormCuponCode] = useState("");
  const [formcuponamount, setFormCuponAmount] = useState("");
  const [formusername, setFormUsername] = useState("");
  const [formusage_number, setFormUsageNumber] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [is_active, setisActive] = useState(true);
  const [cupon_username, setCuponUsername] = useState("");
  const [usage_number, setUsageNumber] = useState("");
  const [total_usage_number, settotal_usage_number] = useState("");
  const [updateProdNo, setUpdatedProdNo] = useState("");
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();

  useEffect(() => {
    if (userRole !== "superAdmin") {
      history.push("/");
    }
    const getCupon = async () => {
      const res = await publicRequest.get(`/supercupon/find/${cuponId}`);
      setCuponCode(res.data?.cupon?.cupon_code);
      setCuponAmount(res.data?.cupon?.cupon_amount);
      const d3 = new Date(res?.data.cupon.from_date).toString("YYYY-MM-dd");
      setStartDate(new Date(d3));
      const d4 = new Date(res?.data.cupon.to_date).toString("YYYY-MM-dd");
      setEndDate(new Date(d4));
      const d1 = new Date(res?.data.cupon.from_date).toDateString();
      setFromDate(d1);
      const d2 = new Date(res?.data.cupon.to_date).toDateString();
      setToDate(d2);
      setFormCuponCode(res.data?.cupon?.cupon_code);
      setFormCuponAmount(res.data?.cupon?.cupon_amount);
      setProductNo(res.data?.cupon?.product_no);
      setUpdatedProdNo(res.data?.cupon?.product_no);
      setisActive(res.data?.cupon?.is_active);
      setCuponUsername(res.data?.cupon?.username);
      setFormUsername(res.data?.cupon?.username);
      setUsageNumber(res.data?.cupon?.current_usage_number);
      setFormUsageNumber(res.data?.cupon?.current_usage_number);
      settotal_usage_number(res.data?.cupon?.total_usage_number);
    };
    getCupon();
  }, [cuponId, userRole, history]);
  const handleCuponUpdate = async () => {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);

    if (formcuponcode === "") {
      toast.error("Please Enter Cupon Code");
    } else if (formcuponamount === "") {
      toast.error("Please Enter Cupon Off Amount");
    } else if (!parseInt(formcuponamount)) {
      toast.error("Please Enter A valid Cupon Off Amount");
    } else if (d1 > d2) {
      toast.error("To Date Should Be Greater Than From Date");
    } else {
      try {
        const data = {
          cupon_code: formcuponcode,
          cupon_amount: formcuponamount,
          username: formusername,
          from_date: startDate,
          to_date: endDate,
          product_no: updateProdNo,
          is_active: is_active,
          total_usage_number: formusage_number,
        };
        const res = await userRequest.put(`/supercupon/${cuponId}`, data);
        setCuponCode(res.data?.user_cupon?.cupon_code);
        setCuponAmount(res.data?.user_cupon?.cupon_amount);
        const d3 = new Date(res?.data?.user_cupon?.from_date).toString(
          "YYYY-MM-dd"
        );
        setStartDate(new Date(d3));
        const d4 = new Date(res?.data?.user_cupon?.to_date).toString(
          "YYYY-MM-dd"
        );
        setEndDate(new Date(d4));
        const d1 = new Date(res?.data?.user_cupon?.from_date).toDateString();
        setFromDate(d1);
        const d2 = new Date(res?.data?.user_cupon?.to_date).toDateString();
        setToDate(d2);
        setFormCuponCode(res.data?.user_cupon?.cupon_code);
        setFormCuponAmount(res.data?.user_cupon?.cupon_amount);
        setProductNo(res.data?.user_cupon?.product_no);
        setUpdatedProdNo(res.data?.user_cupon?.product_no);
        setisActive(res.data?.user_cupon?.is_active);
        setCuponUsername(res.data?.user_cupon?.username);
        setUsageNumber(res.data?.user_cupon?.current_usage_number);
        settotal_usage_number(res.data?.user_cupon?.total_usage_number);
        toast.success("Cupon Updated Successfully");
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    }
  };
  return (
    <>
      <div className="cupon">
        <div className="cuponConatiner">
          <div className="cuponInfo">
            <div className="cuponinfocontainer">
              <h1>Coupon Details</h1>
              <span>
                Coupon Code: <b>{cupon_code}</b>
              </span>
              <br />
              <span>
                Off Amount: <b>{cupon_amount}£</b>
              </span>
              <br />
              <span>
                No Of Products: <b>{product_no}</b>
              </span>
              <br />
              <span>
                From Date: <b>{from_date}</b>
              </span>
              <br />
              <span>
                To Date: <b>{to_date}</b>
              </span>
              <br />
              <span>
                Is Active?: <b>{is_active ? "Yes" : "No"}</b>
              </span>
              <br />
              <span>
                Assigned To?: <b>{cupon_username}</b>
              </span>
              <br />
              <span>
                Maximum Number To Apply? <b>{total_usage_number}</b>
              </span>
              <br />
              <span>
                Currently Applied? <b>{usage_number}</b>
              </span>
            </div>
          </div>
          <div className="cuponForm">
            <div className="cuponformcontainer">
              <h1>Update Coupon</h1>
              <label>Coupon Code</label>
              <input
                type="text"
                placeholder="Cupon Code"
                className="cuponUpdateInput"
                value={formcuponcode}
                onChange={(e) => setFormCuponCode(e.target.value)}
              />
              <br />
              <label>Off Amount In £</label>
              <input
                type="number"
                placeholder="Off Amount In £"
                className="cuponUpdateInput"
                value={formcuponamount}
                onChange={(e) => setFormCuponAmount(e.target.value)}
              />
              <br />
              <label>Username To Assign</label>
              <input
                type="text"
                placeholder="Username"
                className="cuponUpdateInput"
                value={formusername}
                onChange={(e) => setFormUsername(e.target.value)}
              />
              <br />
              <label>No Of Products</label>
              <input
                type="number"
                placeholder="Applied To No Of Products"
                className="cuponUpdateInput"
                value={updateProdNo}
                onChange={(e) => setUpdatedProdNo(e.target.value)}
              />
              <br />
              <label>Usage Number</label>
              <input
                type="number"
                placeholder="How Many Time Can Use"
                className="cuponUpdateInput"
                value={formusage_number}
                onChange={(e) => setFormUsageNumber(e.target.value)}
              />
              <br />
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">From Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">To Date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <br />
              <div className="activate-cupon-section">
                <label>Activate Coupon</label>
                <input
                  type="checkbox"
                  placeholder="Applied To No Of Products"
                  className="cuponUpdateCheckbox"
                  checked={is_active}
                  onChange={(e) => setisActive(!is_active)}
                />
              </div>
              <br />
              <button className="cuponUpdateButton" onClick={handleCuponUpdate}>
                Update Coupon
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SingleAssignedCoupon;
