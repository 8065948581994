import React, { useEffect, useState } from "react";
import "./userList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { IMG_URL, userRequest } from "../../requestMethod";
import PreLoader from "../../components/PreLoader/PreLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../redux/userRedux";
import axios from "axios";

export const UserList = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();
  const token = useSelector((state) => state.user.currentUser.accessToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userRole === "vendor" || userRole === "assistant" || userRole === "developer") {
      history.push("/");
    }
    const head = { headers: { token: `Bearer ${token}` } };
    axios
      .get(`${IMG_URL}api/users`, head)
      .then((res) => {
        setData(res.data.user);
      })
      .catch((err) => {
        // console.log(err);
        dispatch(logout());
      });

    // const getOrders = async () => {
    //   try {
    //     const res = await userRequest.get("users");
    //     setData(res.data.user);
    //   } catch {
    //     dispatch(logout());
    //   }
    // };
    // getOrders();
  }, [count, history, userRole, dispatch, token]);
  const handleDelete = async (param) => {
    const id = param;
    const res = await userRequest.delete(`/users/${id}`);
    if (res.status === 200) {
      toast.success("User Deleted Successfully");
      setCount(count + 1);
    }
    if (res.status !== 200) {
      toast.error("Something Went Wrong");
    }
  };
  const columns = [
    { field: "_id", headerName: "ID", width: 130 },
    { field: "username", headerName: "Username", width: 150 },
    { field: "first_name", headerName: "Name", width: 120 },
    { field: "isAdmin", headerName: "Admin", width: 120 },
    { field: "userTypeRole", headerName: "Role", width: 120 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/user/" + params.row._id}>
              <Edit className="orderListEdit" />
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      {data?.length === 0 ? (
        <PreLoader />
      ) : (
        <>
          <div className="userList">
            <h1>Uers</h1>
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};
