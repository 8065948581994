import React, { useState } from "react";
import "./Cupon.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { userRequest } from "../../requestMethod";
import PreLoader from "../../components/PreLoader/PreLoader";
import { format } from "timeago.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const GetFreeProdCouponList = () => {
  const [cupons, setCupons] = useState([]);
  const [count, setCount] = useState(0);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getOrders = async () => {
      if (userRole !== "superAdmin") {
        history.push("/");
      }
      try {
        const res = await userRequest.get("freeprodcoupon");
        setLoader(false);
        setCupons(res.data);
      } catch {}
    };
    getOrders();
  }, [count, userRole, history]);
  const handleDelete = async (param) => {
    const id = param;
    const res = await userRequest.delete(`/freeprodcoupon/${id}`);
    if (res.status === 200) {
      toast.success("User Deleted Successfully");
      setCount(count + 1);
    }
    if (res.status !== 200) {
      toast.error("Something Went Wrong");
    }
  };
  const columns = [
    { field: "_id", headerName: "ID", width: 90 },
    { field: "cupon_code", headerName: "Cupon Code", width: 150 },
    { field: "buy_no_of_item", headerName: "Buy Product", width: 150 },
    { field: "free_no_of_item", headerName: "Free Product", width: 150 },
    {
      field: "is_active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        return <b>{params.row.is_active ? "Yes" : "No"}</b>;
      },
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      width: 150,
      renderCell: (params) => {
        return <b>{format(params.row.createdAt)}</b>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/freeitemcupons/" + params.row._id}>
              {/* <button className="productListEdit">Edit</button> */}
              <Edit className="orderListEdit" />
            </Link>
            <DeleteOutline
              className="orderListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      {loader ? (
        <PreLoader />
      ) : cupons.length !== 0 ? (
        <>
          <div className="orderList">
            <h1>Buy Item Get Free Item Cupons</h1>
            <DataGrid
              rows={cupons}
              columns={columns}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </>
      ) : (
        <>
          <div className="orderList">
            <h2 style={{ textAlign: "center" }}>No Cupons Found</h2>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default GetFreeProdCouponList;
