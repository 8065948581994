import React, { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Chart } from "../../components/chart/Chart";
import "./product.css";
import { DropzoneArea } from "material-ui-dropzone";
import { Close, CloudDownload } from "@material-ui/icons";
import { IMG_URL, publicRequest } from "../../requestMethod";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../redux/userRedux";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

import {
  man,
  women,
  accessories,
  shoes,
  watches,
  bag,
  kids,
} from "../newProduct/data";
import ReactImageZoom from "react-image-zoom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";

export const Product = () => {
  const location = useLocation();
  const [product, setProduct] = useState([]);
  const productId = location.pathname.split("/")[2];
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [onSale, setOnsale] = useState("");
  const [InStock, setInStock] = useState("");
  const [pStats, setPStats] = useState([]);
  const [count, setCount] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [newDesc, setNewDesc] = useState();
  const [proPrice, setProdPrice] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [StockQuantity, setStockItem] = useState(null);
  const [inputListColor, setInputListColor] = useState([{ color: "" }]);
  const [inputListSize, setInputListSize] = useState([{ size: "" }]);
  const [mainImage, setMainImage] = useState();
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const user = useSelector((state) => state.user.currentUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const log = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      const desContent = editorRef.current.getContent();
      setNewDesc(desContent);
    }
  };
  /* Validate Youtube Link */
  // function isValidYouTubeUrl(url) {
  //   const youtubeRegex =
  //     /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)(&\S*)?$/;
  //   return youtubeRegex.test(url);
  // }

  // handle input change
  const handleInputChangeColor = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListColor];
    list[index][name] = value;
    setInputListColor(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickColor = (e, index) => {
    e.preventDefault();
    const list = [...inputListColor];
    list.splice(index, 1);
    setInputListColor(list);
  };

  // handle click event of the Add button
  const handleAddClickColor = () => {
    setInputListColor([...inputListColor, { color: "" }]);
  };

  // handle input change
  const handleInputChangeSize = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListSize];
    list[index][name] = value;
    setInputListSize(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickSize = (e, index) => {
    e.preventDefault();
    const list = [...inputListSize];
    list.splice(index, 1);
    setInputListSize(list);
  };

  // handle click event of the Add button
  const handleAddClickSize = () => {
    setInputListSize([...inputListSize, { size: "" }]);
  };
  const zoomin = {
    zoomPosition: "original",
    width: 400,
    height: 550,
    zoomWidth: 500,
    img: `${IMG_URL}${mainImage}`,
  };

  useEffect(() => {
    const getProduct = async () => {
      const prod = await publicRequest.get(`products/${productId}`);
      setProduct(prod.data);
      setProductName(prod.data?.post_title);
      setProductDesc(prod.data?.post_name);
      setYoutubeLink(prod.data?.youtube_url);
      setNewDesc(prod.data?.new_description);
      setProdPrice(prod.data?.max_price);
      setOnsale(prod.data?.sale_type);
      setInStock(prod.data?.InStock);
      setStockItem(prod.data?.StockQuantity);
      setCategory(prod.data?.category);
      setSubcategory(prod.data?.sub_category);
      setMainImage(prod.data?.images[0]?.guid);
      const serverclr = prod.data?.color[0]?.split(",");
      let servercolor = [];
      if (
        serverclr !== undefined &&
        serverclr?.length !== 0 &&
        serverclr[0] !== ""
      ) {
        serverclr?.map((e) => {
          if (e !== "") {
            return servercolor.push({ color: e });
          } else if (servercolor.length === 0 && e === "") {
            return (servercolor = [{ color: "" }]);
          }

          return setInputListColor(servercolor);
        });
        setInputListColor(servercolor);
      } else if (
        serverclr === undefined ||
        (serverclr.length !== 0 && serverclr[0] === "") ||
        serverclr.length === 0
      ) {
        setInputListColor([{ color: "" }]);
      }
      const serversze = prod.data?.size[0]?.split(",");
      let serversize = [];
      if (
        serversze !== undefined &&
        serversze?.length !== 0 &&
        serversze[0] !== ""
      ) {
        serversze?.map((e) => {
          if (e !== "") {
            return serversize.push({ size: e });
          } else if (serversize.length === 0 && e === "") {
            return (serversize = [{ size: "" }]);
          }
          return setInputListSize(serversize);
        });
        setInputListSize(serversize);
      } else if (
        serversze === undefined ||
        (serversze.length !== 0 && serversze[0] === "") ||
        serverclr.length === 0
      ) {
        setInputListSize([{ size: "" }]);
      }
    };
    getProduct();
  }, [productId, count, history, userRole]);
  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  useEffect(() => {
    const getStats = async () => {
      try {
        const config = {
          headers: {
            token: `Bearer ${user.accessToken}`,
          },
        };
        axios
          .get(`${IMG_URL}api/orders/income?pid=` + productId, config)
          .then((res) => {
            const list = res.data.sort((a, b) => {
              return a._id - b._id;
            });
            list.map((item) =>
              setPStats((prev) => [
                ...prev,
                { name: MONTHS[item._id - 1], Sales: item.total },
              ])
            );
          })
          .catch((err) => {});
      } catch (err) {}
    };
    getStats();
  }, [productId, MONTHS, user.accessToken]);
  const handlephoto = (fileObject) => {
    setPhotos(fileObject);
  };
  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Extract the filename from the URL or provide a default name
      const filename =
        imageUrl.substring(imageUrl.lastIndexOf("/") + 1) ||
        "downloaded-image.jpg";

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleAllImageDownload = async (imageUrls) => {
    try {
      const promises = imageUrls.map(async (imageUrl) => {
        let new_image_url = `${IMG_URL}${imageUrl?.guid}`;
        const response = await fetch(new_image_url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        // Extract the filename from the URL or provide a default name
        const filename =
          new_image_url.substring(new_image_url.lastIndexOf("/") + 1) ||
          "downloaded-image.jpeg";

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });

      await Promise.all(promises);
    } catch (error) {
      console.error("Error downloading images:", error);
    }
  };

  const handleDeleteImage = async (img) => {
    const productId = product._id;
    const data = {
      productid: productId,
      image_name: img.guid,
      username: user?.username,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        token: `Bearer ${user.accessToken}`,
      },
    };
    axios
      .post(`${IMG_URL}api/products/deleteimage/`, data, config)
      .then((res) => {
        toast.success("Image Deleted Successfully");
        setCount(count + 1);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(logout());
        }
        toast.error("Something Went Wron");
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    function updateProductFunction() {
      let color = "";
      inputListColor.map((clr) => {
        if (clr.color !== "") {
          return (color += clr.color + ",");
        }
        return true;
      });
      let size = "";
      inputListSize.map((siz) => {
        if (siz.size !== "") {
          return (size += siz.size + ",");
        }
        return true;
      });
      const newcolors = [color];
      const newSize = [size];
      const data = {
        post_title: productName,
        post_name: productDesc,
        new_description: newDesc,
        youtube_url: youtubeLink,
        max_price: proPrice,
        sale_price: salePrice,
        category: category,
        size: newSize,
        color: newcolors,
        sub_category: subcategory,
        sale_type: onSale,
        InStock: InStock,
        StockQuantity: StockQuantity,
        updateBy: user?.username,
      };
      const config = {
        headers: {
          "content-type": "application/json",
          token: `Bearer ${user.accessToken}`,
        },
      };
      axios
        .put(`${IMG_URL}api/products/${product._id}`, data, config)
        .then((res) => {
          toast.success("Product Updated Successfully");
          setProduct(res.data);
          setProductName(res.data.post_title);
          setProductDesc(res.data.post_name);
          setYoutubeLink(res.data?.youtube_url);
          setNewDesc(res.data.new_description);
          setNewDesc(res.data.post_name);
          setProdPrice(res.data.max_price);
          setOnsale(res.data.sale_type);
          setInStock(res.data.InStock);
          setStockItem(res.data?.StockQuantity);
          setCategory(res.data.category);
          setSubcategory(res.data.sub_category);
          const serverclr = res.data?.color[0]?.split(",");
          let servercolor = [];
          if (
            serverclr !== undefined &&
            serverclr?.length !== 0 &&
            serverclr[0] !== ""
          ) {
            serverclr?.map((e) => {
              if (e !== "") {
                return servercolor.push({ color: e });
              } else if (servercolor.length === 0 && e === "") {
                return (servercolor = [{ color: "" }]);
              }

              return setInputListColor(servercolor);
            });
            setInputListColor(servercolor);
          } else if (
            serverclr === undefined ||
            (serverclr.length !== 0 && serverclr[0] === "") ||
            serverclr.length === 0
          ) {
            setInputListColor([{ color: "" }]);
          }
          const serversze = res.data?.size[0]?.split(",");
          let serversize = [];
          if (
            serversze !== undefined &&
            serversze?.length !== 0 &&
            serversze[0] !== ""
          ) {
            serversze?.map((e) => {
              if (e !== "") {
                return serversize.push({ size: e });
              } else if (serversze.length === 0 && e === "") {
                return (serversize = [{ size: "" }]);
              }
              return setInputListSize(serversize);
            });
            setInputListSize(serversize);
          } else if (
            serversze === undefined ||
            (serversze.length !== 0 && serversze[0] === "") ||
            serverclr.length === 0
          ) {
            setInputListSize([{ size: "" }]);
          }
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(logout());
          }
        });
      if (photos.length !== 0) {
        const formData = new FormData();
        formData.append("product_id", product._id);
        formData.append("updateBy", user?.username);
        photos.map((img) => {
          return formData.append("images", img);
        });
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            token: `Bearer ${user.accessToken}`,
          },
        };
        axios
          .post(`${IMG_URL}api/products/uploadimages`, formData, config)
          .then((res) => {
            toast.success("Image Updated Successfully");
            setCount(count + 1);
            setPhotos([]);
          })
          .catch((err) => {
            if (err.response.status === 401 || err.response.status === 403) {
              dispatch(logout());
            }
            toast.error("Something Went wrong");
          });
      }
    }
    if (productName === "") {
      toast.error("Please Enter A Valid Product Name");
    } else if (productDesc === "") {
      toast.error("Please Enter A Valid Description Name");
    } else if (proPrice === "" || proPrice === 0 || proPrice === "0") {
      toast.error("Please Enter A Valid Product Price");
    }
    // else if (youtubeLink) {
    //   console.log(youtubeLink);
    //   if (!isValidYouTubeUrl(youtubeLink)) {
    //     toast.error("Please Enter A Valid Youtube Link");
    //   } else {
    //     updateProductFunction();
    //   }
    // }
    else {
      updateProductFunction();
    }
  };
  const handleFirst = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
    ];

    setInputListColor(dd);
  };
  const handleSecond = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
    ];

    setInputListColor(dd);
  };
  const handleThird = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
      {
        color: "11",
      },
      {
        color: "12",
      },
      {
        color: "13",
      },
      {
        color: "14",
      },
      {
        color: "15",
      },
    ];

    setInputListColor(dd);
  };
  const handleFourth = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
      {
        color: "11",
      },
      {
        color: "12",
      },
      {
        color: "13",
      },
      {
        color: "14",
      },
      {
        color: "15",
      },
      {
        color: "16",
      },
      {
        color: "17",
      },
      {
        color: "18",
      },
      {
        color: "19",
      },
      {
        color: "20",
      },
    ];

    setInputListColor(dd);
  };
  const handleSXL = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "S",
      },
      {
        size: "M",
      },
      {
        size: "L",
      },
      {
        size: "XL",
      },
      {
        size: "XXL",
      },
    ];

    setInputListSize(dd);
  };
  const handleWomenSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "34",
      },
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
    ];

    setInputListSize(dd);
  };
  const handleMenSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
      {
        size: "45",
      },
    ];
    setInputListSize(dd);
  };
  const handleUnisexSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "34",
      },
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
      {
        size: "45",
      },
    ];
    setInputListSize(dd);
  };
  return (
    <div className="product">
      <div className="productTitleContainer">
        <h1 className="productTitle">Product</h1>
        <Link to="/newproduct">
          <button className="productAddButton">Create</button>
        </Link>
      </div>
      <div className="productTop">
        <div className="productTopLeft">
          <Chart data={pStats} dataKey="Sales" title="Sales Performance" />
        </div>
        <div className="productTopRight">
          <div className="productInfoTop">
            {product?.length !== 0 ? (
              <img
                src={`${IMG_URL}${product?.images[0]?.guid}`}
                alt=""
                className="productInfoImage"
              />
            ) : (
              <></>
            )}
            <span className="productName">{product.post_title}</span>
          </div>
          <div className="productInfoBottom">
            <div className="productInfoItem">
              <span className="productInfoKey">id:</span>
              <span className="productInfoValue" style={{ marginLeft: "80px" }}>
                {product._id}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">price</span>
              <span className="productInfoValue">
                £{Math.floor(product.max_price)}
              </span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">category:</span>
              <span className="productInfoValue">{product.category}</span>
            </div>
            <div className="productInfoItem">
              <span className="productInfoKey">sub category:</span>
              <span className="productInfoValue">{product.sub_category}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <label className="productpage-label">Product Title</label>
            <input
              type="text"
              placeholder={product.post_title}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
            <label className="productpage-label">
              Product Short Description
            </label>
            <textarea
              type="text"
              placeholder="Short Description"
              value={productDesc}
              onChange={(e) => setProductDesc(e.target.value)}
              style={{ height: "150px" }}
            />
            <label className="productpage-label">Youtube Link</label>
            <input
              type="text"
              placeholder={product?.youtube_url}
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
            <label className="productpage-label">Long Description</label>
            <Editor
              apiKey="thv6ktpzfvfxnmwzq7yfz32lim0e2jmq42cpjb6xbosykiug"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={newDesc}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                  "help",
                  "wordcount",
                ],
                toolbar:
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
            <button onClick={log} className="btn btn-primary mt-3">
              + Add This Description
            </button>
            <label className="productpage-label">Price</label>
            {/* <input
              type="number"
              placeholder={`£${Math.floor(product.max_price)}`}
            /> */}
            <input
              type="number"
              placeholder="£1000"
              value={Math.floor(proPrice)}
              onChange={(e) => setProdPrice(e.target.value)}
            />
            <label className="productpage-label">Category</label>
            <select
              name="onSale"
              id="onSale"
              defaultValue={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option>{product.category}</option>
              <option value="MEN">MEN</option>
              <option value="WOMEN">WOMEN</option>
              <option value="ACCESSORIES">ACCESSORIES</option>
              <option value="BAGS">BAGS</option>
              <option value="SHOES">SHOES</option>
              <option value="WATCHES">WATCHES</option>
              <option value="KIDS">KIDS</option>
            </select>
            <label className="productpage-label">Sub Category</label>
            <select
              name="subcategory"
              id="subcategory"
              defaultValue={subcategory}
              onChange={(e) => setSubcategory(e.target.value)}
            >
              <option value="true">{product.sub_category}</option>
              {category === "MEN" ? (
                <>
                  {man.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "WOMEN" ? (
                <>
                  {women.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "ACCESSORIES" ? (
                <>
                  {accessories.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "BAGS" ? (
                <>
                  {bag.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "SHOES" ? (
                <>
                  {shoes.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "WATCHES" ? (
                <>
                  {watches.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : category === "KIDS" ? (
                <>
                  {kids.map((item) => (
                    <option value={item.navdata} key={item.index}>
                      {item.navdata}
                    </option>
                  ))}
                </>
              ) : (
                <></>
              )}
            </select>
            <label className="productpage-label">In Stock</label>
            <select
              name="inStock"
              id="idStock"
              defaultValue={InStock}
              onChange={(e) => setInStock(e.target.value)}
            >
              <option value={product.InStock}>{product.InStock}</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {InStock === "Yes" && (
              <div className="addProductItem">
                <label>Items In Stock</label>
                <input
                  type="number"
                  placeholder="Items In Stock"
                  value={StockQuantity}
                  onChange={(e) => setStockItem(e.target.value)}
                />
              </div>
            )}
            <label className="productpage-label">On Sale</label>
            <select
              name="onSale"
              id="onSale"
              defaultValue={onSale}
              onChange={(e) => setOnsale(e.target.value)}
            >
              <option value={product.sale_type}>{product.sale_type}</option>
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
            {onSale === "Yes" ? (
              <div className="addProductItem">
                <label>Sale Price</label>
                <input
                  type="number"
                  placeholder="Sale Price"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                />
              </div>
            ) : (
              <></>
            )}
            <label>Color</label>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleFirst}
              >
                1-5
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleSecond}
              >
                1-10
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleThird}
              >
                1-15
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleFourth}
              >
                1-20
              </button>
            </div>
            <div className="Color">
              {inputListColor.map((x, i) => {
                return (
                  <div className="addProductItem" key={i}>
                    <input
                      name="color"
                      placeholder="Add New Color"
                      value={x.color}
                      className="input-random"
                      onChange={(e) => handleInputChangeColor(e, i)}
                    />
                    <div className="btn-box">
                      {inputListColor.length !== 1 && (
                        <button
                          className="mr10 btn btn-outline-danger"
                          style={{ marginTop: "10px", marginRight: "10px" }}
                          onClick={(e) => handleRemoveClickColor(e, i)}
                        >
                          Remove
                        </button>
                      )}
                      {inputListColor.length - 1 === i && (
                        <button
                          onClick={handleAddClickColor}
                          className="btn btn-outline-success"
                          style={{ marginTop: "10px" }}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <label>Size</label>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleSXL}
              >
                S-XXL
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleWomenSize}
              >
                34-44
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleMenSize}
              >
                35-45
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ width: "100px", marginLeft: "5px" }}
                onClick={handleUnisexSize}
              >
                34-45
              </button>
            </div>
            <div className="Size">
              {inputListSize.map((x, i) => {
                return (
                  <div className="addProductItem" key={i}>
                    <input
                      name="size"
                      placeholder="Add New Size"
                      value={x.size}
                      className="input-random"
                      onChange={(e) => handleInputChangeSize(e, i)}
                    />
                    <div className="btn-box">
                      {inputListSize.length !== 1 && (
                        <button
                          className="mr10 btn btn-outline-danger"
                          style={{ marginTop: "10px", marginRight: "10px" }}
                          onClick={(e) => handleRemoveClickSize(e, i)}
                        >
                          Remove
                        </button>
                      )}
                      {inputListSize.length - 1 === i && (
                        <button
                          onClick={handleAddClickSize}
                          className="btn btn-outline-success"
                          style={{ marginTop: "10px" }}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="productFormRight">
            <div className="productUpload">
              <div className="react-imagezoom">
                <ReactImageZoom {...zoomin} />
              </div>
              {product?.images?.map((img) => (
                <>
                  <div key={img.old_id}>
                    <div className="product-image-btns">
                      <a
                        href="/"
                        onClick={(event) => {
                          event.preventDefault();
                          downloadImage(`${IMG_URL}${img.guid}`);
                        }}
                      >
                        <CloudDownload />
                      </a>
                      <div
                        className="product-delete-image"
                        onClick={() => handleDeleteImage(img)}
                      >
                        <Close style={{ color: "red" }} />
                      </div>
                    </div>
                    <img
                      src={`${IMG_URL}${img.guid}`}
                      alt=""
                      className="productUploadImg"
                      onClick={() => {
                        setMainImage(img.guid);
                      }}
                    />
                  </div>
                </>
              ))}
            </div>
            <a
              href="/"
              onClick={(event) => {
                event.preventDefault();
                handleAllImageDownload(product?.images);
              }}
            >
              <button className="btn btn-outline-primary mb-3 mt-1">
                Download All Images
              </button>
            </a>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              fileObjects={photos}
              filesLimit={40}
              maxFileSize={5000000}
              onChange={(fileObject) => handlephoto(fileObject)}
            />
            <button className="productButton" onClick={handleUpdate}>
              Update
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
