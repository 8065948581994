import React, { useEffect } from "react";
import "./user.css";
import {
  // CalendarToday,
  // LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  // Publish,
} from "@material-ui/icons";
import { Link, useLocation } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const User = () => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [isAdmin, setIsAdmin] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("");
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();

  useEffect(() => {
    if (userRole !== "superAdmin") {
      history.push("/");
    }
    const getUser = async () => {
      const res = await userRequest.get(`users/find/${userId}`);
      setUser(res.data);
      setUsername(res.data.username);
      setFirstName(res.data.first_name);
      setLastName(res.data.last_name);
      setIsAdmin(res.data.isAdmin);
      setPhone(res.data.phone);
      setEmail(res.data.email);
      setUserTypeRole(res.data.userTypeRole);
    };
    getUser();
  }, [userId, history, userRole]);
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (password !== "") {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        password: password,
        isAdmin: isAdmin,
        userTypeRole: userTypeRole,
      };
      const res = await userRequest.put(`users/admin/${userId}`, data);
      if (res.status === 200) {
        setUser(res.data);
        setUsername(res.data.username);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setIsAdmin(res.data.isAdmin);
        setPhone(res.data.phone);
        setEmail(res.data.email);
        setUserTypeRole(res.data.userTypeRole);
        toast.success("User Updated Successfully");
      } else {
        toast.error("Something Went Wrong");
      }
    }
    if (password === "") {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone,
        isAdmin: isAdmin,
        userTypeRole: userTypeRole,
      };
      const res = await userRequest.put(`users/admin/${userId}`, data);
      if (res.status === 200) {
        setUser(res.data);
        setUsername(res.data.username);
        setFirstName(res.data.first_name);
        setLastName(res.data.last_name);
        setIsAdmin(res.data.isAdmin);
        setPhone(res.data.phone);
        setEmail(res.data.email);
        setUserTypeRole(res.data.userTypeRole);
        toast.success("User Updated Successfully");
      } else {
        toast.error("Something Went Wrong");
      }
    }
  };

  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Edit User</h1>
        <Link to="/newuser">
          <button className="userAddButton">Create</button>
        </Link>
      </div>
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            <img
              src="https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png"
              alt=""
              className="userShowImg"
            />
            <div className="userShowTopTitle">
              <span className="userShowUsername">{user?.first_name} {user?.last_name}</span>
              <span className="userShowUserTitle">
                {user?.isAdmin ? "Admin" : "User"}
              </span>
            </div>
          </div>
          <div className="userShowBottom">
            <span className="userShowUserTitle">Account Details</span>
            <div className="userShowInfo">
              ID:
              <b>
                <div className="userShowInfoTitle">{user?._id}</div>
              </b>
            </div>
            <div className="userShowInfo">
              Username:
              <PermIdentity className="userShowIcon" />
              <b>
                <div className="userShowInfoTitle">{user?.username}</div>
              </b>
            </div>
            <div className="userShowInfo">
              First Name:
              <b>
                <div className="userShowInfoTitle">{user?.first_name}</div>
              </b>
            </div>
            <div className="userShowInfo">
              Last Name:
              <b>
                <div className="userShowInfoTitle">{user?.last_name}</div>
              </b>
            </div>
            <div className="userShowInfo">
              Is Admin:
              <b>
                <div className="userShowInfoTitle">
                  {user?.isAdmin ? "Yes" : "No"}
                </div>
              </b>
            </div>
            <div className="userShowInfo">
              User Role:
              <b>
                <div className="userShowInfoTitle">{user?.userTypeRole}</div>
              </b>
            </div>
            <span className="userShowUserTitle">Contact Details</span>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <div className="userShowInfoTitle">{user?.phone}</div>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <div className="userShowInfoTitle">{user?.email}</div>
            </div>
          </div>
        </div>
        <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"
                  placeholder="Username"
                  className="userUpdateInput"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="userUpdateItem">
                <label>First Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  className="userUpdateInput"
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="userUpdateItem">
                <label>Last Name</label>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="userUpdateInput"
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  className="userUpdateInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="number"
                  placeholder="Phone Number"
                  className="userUpdateInput"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="userUpdateItem">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Update Password"
                  className="userUpdateInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <label>Admin</label>
                <input
                  type="checkbox"
                  style={{ marginLeft: "20px" }}
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
                />
              </div>
              <div className="userUpdateItem">
                <select
                  value={userTypeRole}
                  onChange={(e) => {
                    setUserTypeRole(e.target.value);
                    // console.log(e.target.value);
                    if (e.target.value === "user") {
                      setIsAdmin(false);
                    } else {
                      setIsAdmin(true);
                    }
                  }}
                >
                  <option>user</option>
                  <option>vendor</option>
                  <option>assistant</option>
                  <option>superAdmin</option>
                  <option>developer</option>
                </select>
              </div>
              <button className="userUpdateButton" onClick={handleUpdate}>
                Update
              </button>
            </div>
            {/* <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img
                  className="userUpdateImg"
                  src="https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png"
                  alt=""
                />
                <label htmlFor="file">
                  <Publish className="userUpdateIcon" />
                </label>
                <input type="file" id="file" style={{ display: "none" }} />
              </div>
              <button className="userUpdateButton" onClick={handleUpdate}>
                Update
              </button>
            </div> */}
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
