import React from "react";
import "./productList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteProduct, getProducts } from "../../redux/apiCalls";
import { IMG_URL } from "../../requestMethod";
import PreLoader from "../../components/PreLoader/PreLoader";
import { useHistory } from "react-router-dom";

export const ProductList = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();
  useEffect(() => {
    // if (userRole === "assistant") {
    //   history.push("/");
    // }
    getProducts(dispatch);
  }, [dispatch, history, userRole]);
  const handleDelete = (id) => {
    deleteProduct(id, dispatch);
  };
  const columns = [
    // { field: "_id", headerName: "ID", width: 200 },
    {
      field: "product",
      headerName: "Product",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img
              className="productListImg"
              src={`${IMG_URL}${params?.row?.images[0]?.guid}`}
              alt=""
            />
            {params.row.post_title}
          </div>
        );
      },
    },
    { field: "category", headerName: "Category", width: 150 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,
    // },
    {
      field: `$max_price`,
      headerName: "Price",
      width: 120,
      renderCell: (params) => {
        return <b>£{Math.floor(params.row.max_price)}</b>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row._id}>
              {/* <button className="productListEdit">Edit</button> */}
              <Edit className="productListEdit" />
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
    {
      field: `$updatedAt`,
      headerName: "Last Updated",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <b>{`${params.row.updatedAt.split("T")[0]}`} </b>
          </>
        );
      },
    },
    {
      field: `$updateBy`,
      headerName: "UpdatedBy",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <b>
              {params.row.updateBy === undefined ? "N/A" : params.row.updateBy}{" "}
            </b>
          </>
        );
      },
    },
  ];
  return (
    <>
      {products?.length === 0 ? (
        <PreLoader />
      ) : (
        <div className="productList">
          <h1>Products</h1>
          <DataGrid
            rows={products}
            columns={columns}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
          />
        </div>
      )}
    </>
  );
};
