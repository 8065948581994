import React, { useEffect, useState } from "react";
import "./widgetLg.css";
import { IMG_URL } from "../../requestMethod";
import { format } from "timeago.js";
import { logout } from "../../redux/userRedux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Visibility } from "@material-ui/icons";

export const WidgetLg = () => {
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.currentUser.accessToken);
  const history = useHistory();

  useEffect(() => {
    // const getOrders = async () => {
    //   try {
    //     const res = await userRequest.get("order");
    //     setOrders(res.data);
    //   } catch {
    //     dispatch(logout());
    //   }
    // };
    const head = { headers: { token: `Bearer ${token}` } };
    axios
      .get(`${IMG_URL}api/order`, head)
      .then((res) => {
        setOrders(res.data.slice(0, 10));
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(logout());
        }
      });
    // getOrders();
  }, [dispatch, token]);
  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };
  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest transaction</h3>
      <table className="widgetLgTable">
        <tr className="widgetLgTr">
          <th className="widgetLgTh Id">Customer</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        {orders.map((order) => (
          <tr className="widgetLgTr" key={order._id}>
            <td className="widgetLgUser">
              <button
                className="widgetSmButton"
                onClick={() => history.push(`/order/${order?._id}`)}
              >
                <Visibility className="widgetSmIcon" />
                <span className="widgetLgName">
                  {order.userId !== "Guest" ? "User" : "Guest"}
                </span>
              </button>
              {/* <span className="widgetLgNameMob">
                {order.userId.slice(0, 5)}
              </span> */}
            </td>
            <td className="widgetLgDate">{format(order.createdAt)}</td>
            <td className="widgetLgAmount">£{Math.floor(order.amount)}</td>
            <td className="widgetLgStatus">
              <Button type={order.status} />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
};
