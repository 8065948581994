import React, { useState } from "react";
import "./Order.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { userRequest } from "../../requestMethod";
import PreLoader from "../../components/PreLoader/PreLoader";
import { format } from "timeago.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [count, setCount] = useState(0);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [deleteOrderId, setDeleteOrderId] = useState(null);

  useEffect(() => {
    const getOrders = async () => {
      if (userRole === "vendor") {
        history.push("/");
      }
      try {
        const res = await userRequest.get("order");
        setOrders(res.data);
        setLoader(false);
      } catch {}
    };
    getOrders();
  }, [count, userRole, history]);
  const handleDeleteOrder = async () => {
    const res = await userRequest.delete(`/order/${deleteOrderId}`);
    if (res.status === 200) {
      toast.success("Order Deleted Successfully");
      setCount(count + 1);
    }
    if (res.status !== 200) {
      toast.error("Something Went Wrong");
    }
  };
  const columns = [
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return <b>£{Math.floor(params.row.amount)}</b>;
      },
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      width: 150,
      renderCell: (params) => {
        return <b>{format(params.row.createdAt)}</b>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      renderCell: (params) => {
        return <b>{params.row.createdAt.split("T")[0]}</b>;
      },
    },
    {
      field: "Address",
      headerName: "Username",
      width: 150,
      renderCell: (params) => {
        return <b>{params.row.address.first_name}</b>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/order/" + params.row._id}>
              {/* <button className="productListEdit">Edit</button> */}
              <Edit className="orderListEdit" />
            </Link>
            <DeleteOutline
              className="orderListDelete"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => {
                // handleDelete(params.row._id)
                setDeleteOrderId(params.row._id);
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      {loader ? (
        <PreLoader />
      ) : orders.length !== 0 ? (
        <>
          <div className="orderList">
            <h1>Orders</h1>
            <div
              className="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Delete Order?
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Are you sure you want to delete order?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      onClick={handleDeleteOrder}
                    >
                      Delete Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DataGrid
              rows={orders}
              columns={columns}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              pageSize={10}
              rowsPerPageOptions={[10]}
              checkboxSelection
            />
          </div>
        </>
      ) : (
        <>
          <div className="orderList">
            <h2 style={{ textAlign: "center" }}>No Orders Found</h2>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default OrderList;
