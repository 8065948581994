import React, { useEffect } from "react";
import "./login.css";
import logo from "../../assets/logo.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/apiCalls";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import PreLoader from "../../components/PreLoader/PreLoader";
import "react-toastify/dist/ReactToastify.css";
import { logout } from "../../redux/userRedux";
// import { useDispatch } from "react-redux";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const user = useSelector((state) => state.user);
  const { isFetching, error } = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "") {
      toast.error("Please Enter Username");
    } else if (password === "") {
      toast.error("Please Enter Password");
    } else {
      login(dispatch, { username, password });
    }
  };
  useEffect(() => {
    if(!user?.currentUser?.isAdmin){
      dispatch(logout());
    }
    else if (user?.currentUser?.isAdmin) {
      history.push("/");
    }
    // else if(user?.currentUser === null){
    //   setError("Invalid Credentials");
    // }
  }, [user?.currentUser, history, dispatch]);
  return (
    <>
      {isFetching ? (
        <PreLoader />
      ) : (
        <div className="maincontainer">
          <div className="containerfirst">
            <div className="sidenav">
              <div className="login-main-text">
                <img
                  src={logo}
                  alt=""
                  style={{ width: "30%", height: "30%" }}
                />
                <h2>
                  Fabric Designer Outlet<br></br> Login Page
                </h2>
                <p>Login from here to access admin dashboard.</p>
              </div>
            </div>
          </div>
          <div className="containersecond">
            <div className="wrapper">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <div className="text-center mt-4 name">
                Fabric Designer Outlet
              </div>
              <form className="p-3 mt-3">
                <div className="form-field d-flex align-items-center">
                  {/* <span className="fa fa-user"></span> */}
                  <i className="fa fa-user" aria-hidden="true"></i>
                  <input
                    type="text"
                    name="userName"
                    id="userName"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="form-field d-flex align-items-center">
                  <span className="fa fa-lock"></span>
                  <input
                    type="password"
                    name="password"
                    id="pwd"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button className="btn mt-3" onClick={handleLogin}>
                  Login
                </button>
              </form>
              <div className="text-center fs-6">
                <a href="/">Forget password?</a>
              </div>
              <div className="text-center fs-6">
                {error && <p className="text-danger">Invalid Credentials</p>}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Login;
