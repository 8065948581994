import React, { useEffect, useRef, useState } from "react";
import "./blog.css";
import axios from "axios";
import { IMG_URL } from "../../requestMethod";
import { useLocation } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import { logout } from "../../redux/userRedux";

const UpdateBlog = () => {
  const location = useLocation();
  const blogId = location.pathname.split("/")[2];

  const dispatch = useDispatch();

  const [blog, setBlog] = useState(null);
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [meta, setMeta] = useState(null);
  const [description, setDescription] = useState();
  const [mainImage, setMainImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [imageSelected, setImageSelected] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getOrder = async () => {
      axios
        .get(`${IMG_URL}api/blog/find/${blogId}`)
        .then((res) => {
          setBlog(res?.data);
          setTitle(res?.data.title);
          setSlug(res?.data?.slug);
          setMeta(res?.data?.meta);
          setDescription(res?.data?.description);
          setMainImage(res?.data?.image[0]?.url);
          setMainImagePreview(res?.data?.image[0]?.url);
        })
        .catch((err) => {});
    };
    getOrder();
  }, [blogId, count]);

  const editorRef = useRef(null);

  const user = useSelector((state) => state.user.currentUser);

  const log = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      const desContent = editorRef.current.getContent();
      setDescription(desContent);
      if (title === null || title === "") {
        toast.error("Please Enter Title");
      } else if (slug === null || slug === "") {
        toast.error("Please Enter Enter");
      } else if (desContent === "") {
        toast.error("Please Enter Description");
      } else {
        const formData = new FormData();
        formData.append("id", blogId);
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("meta", meta);
        formData.append("description", desContent);
        formData.append("images", mainImage);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            token: `Bearer ${user.accessToken}`,
          },
        };
        axios
          .put(`${IMG_URL}api/blog/${blogId}`, formData, config)
          .then((res) => {
            toast.success("Blog Updated.");
            setCount(count + 1);
            setImageSelected(false);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    }
  };

  const handleMainImage = (obj) => {
    setMainImage(obj.target.files[0]);
    setMainImagePreview(URL.createObjectURL(obj.target.files[0]));
    setImageSelected(true);
  };
  const handleDeleteImage = async (img) => {
    const blogId = blog._id;
    const data = {
      blog_id: blogId,
      image_name: img,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        token: `Bearer ${user.accessToken}`,
      },
    };
    axios
      .post(`${IMG_URL}api/blog/deleteimage`, data, config)
      .then((res) => {
        toast.success("Image Deleted Successfully");
        setCount(count + 1);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(logout());
        }
        toast.error("Something Went Wrong");
      });
  };

  return (
    <div className="blog">
      <h1>Update Blog</h1>
      <div className="addBlog">
        <label>Title</label>
        <input
          type="text"
          placeholder="Blog Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="addBlog">
        <label>Slug</label>
        <input
          type="text"
          placeholder="Blog Slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
      </div>
      <div className="addBlog">
        <label>Meta</label>
        <input
          type="text"
          placeholder="Blog Meta"
          value={meta}
          onChange={(e) => setMeta(e.target.value)}
        />
      </div>
      {mainImagePreview && !imageSelected && (
        <div className="addBlog">
          <label>Current Image</label>
          <div>
            <div
              className="product-delete-image"
              onClick={() => handleDeleteImage(mainImagePreview)}
            >
              <Close style={{ color: "red" }} />
            </div>
            <img
              src={`${IMG_URL}${mainImagePreview}`}
              alt=""
              className="productUploadImg"
            />
          </div>
        </div>
      )}
      <div className="addBlog">
        <label>Cover Image</label>
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={handleMainImage}
        />
      </div>
      <div className="addBlog">
        {mainImage ? <img src={mainImagePreview} alt="" /> : <></>}
      </div>
      <Editor
        apiKey="thv6ktpzfvfxnmwzq7yfz32lim0e2jmq42cpjb6xbosykiug"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={description}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <button className="btn btn-outline-primary mt-4 mb-4" onClick={log}>
        Update Blog
      </button>
      <ToastContainer />
    </div>
  );
};

export default UpdateBlog;
