import {
  Menu,
  Close,
  LineStyle,
  Search,
  Add,
  PermIdentity,
  AttachMoney,
  Receipt,
  Storefront,
} from "@material-ui/icons";
import React, { useState } from "react";
import styled from "styled-components";
import "./sidebar.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  background-color: white;
  margin-top: -10px;
  margin-left: -15px;
`;
const Left = styled.div`
  margin-top: 10px;
  flex: 1;
  display: flex;
`;
const MobileIconContainer = styled.div`
  margin-top: 7px;
  display: flex;
  z-index: 5;
`;
const MobileRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: -10px;
`;
const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  justify-content: flex-start !important;
  margin-top: 20px;
`;
const List = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
`;
const ListItem = styled.li`
  padding: 10px 10px;
  font-size: 16px;
  list-style: none;
  cursor: pointer;
`;
const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  padding-left: 10px;
  z-index: 4;
`;
const MobileNavbar = () => {
  const [showNav, setShowNav] = useState(false);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  return (
    <Container>
      <Left>
        <MenuItem>
          <Menu
            onClick={() => setShowNav(!showNav)}
            style={{ fontSize: "28px" }}
          />
          <div className={showNav ? "nav-menu active" : "nav-menu"}>
            <h3 style={{ textAlign: "center" }}>Admin Dashboard</h3>
            <MobileIconContainer>
              <MobileRight>
                <Close
                  onClick={() => {
                    setShowNav(!showNav);
                  }}
                  style={{ cursor: "pointer", marginRight: "10px" }}
                />
              </MobileRight>
            </MobileIconContainer>
            {userRole === "superAdmin" ? (
              <ListContainer>
                <List>
                  <ListItem>
                    <NavLink
                      exact
                      to="/"
                      onClick={() => {
                        setShowNav(false);
                      }}
                      className={(isActive) =>
                        isActive
                          ? "link sidebarListItem active"
                          : "link sidebarListItem"
                      }
                    >
                      <li className="list-item">
                        <LineStyle
                          className="sidebaricon"
                          style={{ fontSize: "20px", marginRight: "5px" }}
                        />
                        <span style={{ fontSize: "12px" }}>Home</span>
                      </li>
                    </NavLink>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <div className="sidebarMenu">
                      <h3 className="sidebarTitle">User Section</h3>
                      <ul className="sidebarList">
                        <NavLink
                          to="/users"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <PermIdentity className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>Users</span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/newuser"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Add className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>Add User</span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/searchuser"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Search className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Search Users
                            </span>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <div className="sidebarMenu">
                      <h3 className="sidebarTitle">Product Section</h3>
                      <ul className="sidebarList">
                        <NavLink
                          to="/products"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Storefront className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>Products</span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/newproduct"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Add className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Add Products
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/searchproduct"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Search className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Search Products
                            </span>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <div className="sidebarMenu">
                      <h3 className="sidebarTitle">Coupon Section</h3>
                      <ul className="sidebarList">
                        <NavLink
                          to="/cupons"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Receipt className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>Coupons</span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/assigned/cupons"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Receipt className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Assigned Coupons
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/freeitem/cupons"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Receipt className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Free Item Coupons
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/create/cupon"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Add className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Create New Coupon
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/create/free-item-coupon"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Add className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Create Free Item Coupon
                            </span>
                          </li>
                        </NavLink>
                        <NavLink
                          to="/create/user/assigned/coupon"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <Add className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>
                              Assign Coupon To User
                            </span>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <div className="sidebarMenu">
                      <h3 className="sidebarTitle">Order Section</h3>
                      <ul className="sidebarList">
                        <NavLink
                          to="/orders"
                          onClick={() => {
                            setShowNav(false);
                          }}
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">
                            <AttachMoney className="sidebarIcon" />
                            <span style={{ fontSize: "12px" }}>Orders</span>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <div className="sidebarMenu">
                      <h3 className="sidebarTitle">Blog Section</h3>
                      <ul className="sidebarList">
                        <NavLink
                          to="/all-blogs"
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">All Blogs</li>
                        </NavLink>
                        <NavLink
                          to="/create-blog"
                          className={(isActive) =>
                            isActive
                              ? "link sidebarListItem active"
                              : "link sidebarListItem"
                          }
                        >
                          <li className="list-item">Create Blogs</li>
                        </NavLink>
                      </ul>
                    </div>
                  </ListItem>
                </List>
              </ListContainer>
            ) : userRole === "vendor" ? (
              <>
                <ListItem>
                  <NavLink
                    exact
                    to="/"
                    onClick={() => {
                      setShowNav(false);
                    }}
                    className={(isActive) =>
                      isActive
                        ? "link sidebarListItem active"
                        : "link sidebarListItem"
                    }
                  >
                    <li className="list-item">
                      <LineStyle
                        className="sidebaricon"
                        style={{ fontSize: "20px", marginRight: "5px" }}
                      />
                      <span style={{ fontSize: "12px" }}>Home</span>
                    </li>
                  </NavLink>
                </ListItem>
                <ListItem>
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Product Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/products"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Storefront className="sidebarIcon" />
                          Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/newproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Add className="sidebarIcon" />
                          Add Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/searchproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Search className="sidebarIcon" />
                          Search Products
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
                <ListItem>
                  {" "}
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Blog Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/all-blogs"
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">All Blogs</li>
                      </NavLink>
                      <NavLink
                        to="/create-blog"
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">Create Blogs</li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
              </>
            ) : userRole === "assistant" ? (
              <>
                <ListItem>
                  <ListItem>
                    <NavLink
                      exact
                      to="/"
                      onClick={() => {
                        setShowNav(false);
                      }}
                      className={(isActive) =>
                        isActive
                          ? "link sidebarListItem active"
                          : "link sidebarListItem"
                      }
                    >
                      <li className="list-item">
                        <LineStyle
                          className="sidebaricon"
                          style={{ fontSize: "20px", marginRight: "5px" }}
                        />
                        <span style={{ fontSize: "12px" }}>Home</span>
                      </li>
                    </NavLink>
                  </ListItem>{" "}
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Product Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/products"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Storefront className="sidebarIcon" />
                          Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/newproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Add className="sidebarIcon" />
                          Add Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/searchproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Search className="sidebarIcon" />
                          Search Products
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
                <ListItem>
                  {" "}
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Order Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/orders"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <AttachMoney className="sidebarIcon" />
                          Orders
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
              </>
            ) : userRole === "developer" ? (
              <>
                <ListItem>
                  <NavLink
                    exact
                    to="/"
                    onClick={() => {
                      setShowNav(false);
                    }}
                    className={(isActive) =>
                      isActive
                        ? "link sidebarListItem active"
                        : "link sidebarListItem"
                    }
                  >
                    <li className="list-item">
                      <LineStyle
                        className="sidebaricon"
                        style={{ fontSize: "20px", marginRight: "5px" }}
                      />
                      <span style={{ fontSize: "12px" }}>Home</span>
                    </li>
                  </NavLink>
                </ListItem>
                <ListItem>
                  {" "}
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Product Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/products"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Storefront className="sidebarIcon" />
                          Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/newproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Add className="sidebarIcon" />
                          Add Products
                        </li>
                      </NavLink>
                      <NavLink
                        to="/searchproduct"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <Search className="sidebarIcon" />
                          Search Products
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
                <ListItem>
                  {" "}
                  <div className="sidebarMenu">
                    <h3 className="sidebarTitle">Order Section</h3>
                    <ul className="sidebarList">
                      <NavLink
                        to="/orders"
                        onClick={() => {
                          setShowNav(false);
                        }}
                        className={(isActive) =>
                          isActive
                            ? "link sidebarListItem active"
                            : "link sidebarListItem"
                        }
                      >
                        <li className="list-item">
                          <AttachMoney className="sidebarIcon" />
                          Orders
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </ListItem>
              </>
            ) : (
              <></>
            )}
          </div>
        </MenuItem>
      </Left>
    </Container>
  );
};

export default MobileNavbar;
