import React, { useState } from "react";
import "./Order.css";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import { IMG_URL } from "../../requestMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/userRedux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import { Close } from "@material-ui/icons";
import ReactImageZoom from "react-image-zoom";

const SingleOrder = () => {
  const location = useLocation();
  const orderId = location.pathname.split("/")[2];
  const [order, setOrder] = useState(null);
  const [status, setstatus] = useState(null);
  const [trackingnumber1, setTrackingNumber1] = useState(null);
  const [trackingnumber2, setTrackingNumber2] = useState(null);
  const [trackingnumber3, setTrackingNumber3] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [mainImage, setMainImage] = useState();
  const [count, setCount] = useState(0);
  const [photos, setPhotos] = useState([]);

  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const user = useSelector((state) => state.user.currentUser);
  const accessToken = useSelector(
    (state) => state.user.currentUser.accessToken
  );

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userRole === "vendor") {
      history.push("/");
    }
    const getOrder = async () => {
      const config = {
        headers: {
          "content-type": "application/json",
          token: `Bearer ${accessToken}`,
        },
      };
      axios
        .get(`${IMG_URL}api/order/find/${orderId}`, config)
        .then((res) => {
          // const res = await userRequest.get(`order/find/${orderId}`);
          setOrder(res.data.order);
          setstatus(res.data.order.status);
          setTrackingNumber1(res.data?.order?.tracking_number);
          setMainImage(res.data?.order?.images[0]?.url);
          if (res.data?.order?.tracking_number2) {
            setTrackingNumber2(res.data?.order?.tracking_number2);
          }
          if (res.data?.order?.tracking_number2) {
            setTrackingNumber3(res.data?.order?.tracking_number3);
          }
          const userId = res.data.order.userId;
          axios
            .get(`${IMG_URL}api/users/find/${userId}`, config)
            .then((res) => {
              setUserInfo(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {});
    };
    getOrder();
  }, [orderId, history, userRole, accessToken, count]);
  var d = new Date(order?.createdAt);
  const orderdate = d.toDateString();

  const handlephoto = (fileObject) => {
    setPhotos(fileObject);
  };

  const handleDeleteImage = async (img) => {
    const orderId = order._id;
    console.log(img.url);
    const data = {
      order_id: orderId,
      image_name: img.url,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        token: `Bearer ${user.accessToken}`,
      },
    };
    axios
      .post(`${IMG_URL}api/order/deleteimage/`, data, config)
      .then((res) => {
        toast.success("Image Deleted Successfully");
        setCount(count + 1);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(logout());
        }
        toast.error("Something Went Wron");
      });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      status: status,
      tracking_number: trackingnumber1,
      tracking_number2: trackingnumber2,
      tracking_number3: trackingnumber3,
    };
    const res = await userRequest.put(`order/${orderId}`, data);
    if (res.status === 200) {
      setOrder(res.data);
      toast.success("Order Updated Successfully");
    }
    if (photos.length !== 0) {
      const formData = new FormData();
      formData.append("order_id", order._id);
      photos.map((img) => {
        return formData.append("images", img);
      });
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          token: `Bearer ${user.accessToken}`,
        },
      };
      axios
        .post(`${IMG_URL}api/order/uploadimages`, formData, config)
        .then((res) => {
          setCount(count + 1);
          setPhotos([]);
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(logout());
          }
          toast.error("Something Went wrong");
        });
    }
    if (res.status !== 200) {
      toast.error("Something Went Wrong");
    }
  };

  const zoomin = {
    zoomPosition: "top",
    width: 400,
    height: 550,
    zoomWidth: 500,
    img: `${IMG_URL}${mainImage}`,
  };

  return (
    <div className="SingleOrder">
      <h1>Order</h1>
      <div className="orderinfo">
        <h3>Order Detail</h3>
        <div className="infoContainer">
          <div className="infoleft">
            <span>Order Id: </span>
            <b>{order?._id}</b>
            <br />
            <span>Order Date: </span>
            <b>{orderdate}</b>
          </div>
          <div className="inforight">
            <span>Total Amount: </span>
            <b>£{Math.floor(order?.amount)}</b>
            <br />
            <span>Order Status: </span>
            <b>{order?.status}</b>
          </div>
          <div className="inforight">
            <span>Update Status: </span>
            <br />
            <select
              onChange={(e) => setstatus(e.target.value)}
              value={status}
              style={{ width: "64%" }}
            >
              <option>on hold</option>
              <option>in-process</option>
              <option>completed</option>
              <option>payment-failed</option>
              <option>canceled</option>
            </select>{" "}
            <br />
            <input
              type="text"
              placeholder="Tracking Number 1"
              value={trackingnumber1}
              onChange={(e) => setTrackingNumber1(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <input
              type="text"
              placeholder="Tracking Number 2"
              value={trackingnumber2}
              onChange={(e) => setTrackingNumber2(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <input
              type="text"
              placeholder="Tracking Number 3"
              value={trackingnumber3}
              onChange={(e) => setTrackingNumber3(e.target.value)}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <br />
            <button className="orderbtn" onClick={handleUpdate}>
              Update Order
            </button>
          </div>
        </div>
      </div>
      <div className="orderinfo">
        <h3>Order Tracking Images</h3>
        <div className="OrderMedia">
          <div className="orderMediaLeft">
            <div className="orderPhotoZoom">
              {mainImage && <ReactImageZoom {...zoomin} height='200' width='200'/>}
            </div>
            <div className="order-image-container">
              {order?.images?.map((img) => (
                <>
                  <div key={img.url}>
                    <div
                      className="product-delete-image"
                      onClick={() => handleDeleteImage(img)}
                    >
                      <Close style={{ color: "red" }} />
                    </div>
                    <img
                      src={`${IMG_URL}${img.url}`}
                      alt=""
                      className="productUploadImg"
                      onClick={() => {
                        setMainImage(img.url);
                      }}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="orderMediaLeft">
            <DropzoneArea
              acceptedFiles={["image/*"]}
              fileObjects={photos}
              filesLimit={40}
              maxFileSize={5000000}
              onChange={(fileObject) => handlephoto(fileObject)}
            />
            {/* <button className="productButton" onClick={handleUpdate}>
              Update Tracking Images
            </button> */}
          </div>
        </div>
      </div>
      <div className="orderinfo">
        <h3>Shipping Address</h3>
        <div className="infoContainer">
          <div className="infoleft">
            {/* <span>Address 1: </span> */}
            <b>
              {order?.address?.first_name} {order?.address?.last_name}
            </b>
            <br />
            <b>{order?.address?.address1}</b>
            <br />
            {/* <span>City: </span> */}
            <b>{order?.address?.city}</b>
            <br />
            {/* <span>Region: </span> */}
            <b>{order?.address?.region}</b>
            <br />
            {/* <span>Country: </span> */}
            <b>{order?.address?.country}</b>
            <br />
            {/* <span>Zip Code: </span> */}
            <b>{order?.address?.zip}</b>
            <br />
            <span>Phone Number: </span>
            <b>{userInfo?.phone}</b>
            <br />
            <span>Email: </span>
            <b>{order?.address?.email}</b>
            <br />
            <span>IP Address: </span>
            <b>{order?.ip_address.split(":")[3]}</b>
          </div>
          <div className="inforight">
            <span>User Id: </span>
            <Link to={`/user/${order?.userId}`}>
              <b>{order?.userId}</b>
            </Link>
            <br />
            <span>Shipping Address: </span>
            <b>{order?.address?.address2}</b>
            <br />
            {/* <span>Name: </span>
            <b>
              {order?.address?.first_name} {order?.address?.last_name}
            </b>
            <br />
            <span>Phone Number: </span>
            <b>{userInfo?.phone}</b>
            <br />
            <span>Email: </span>
            <b>{order?.address?.email}</b>
            <br /> */}
            <span>IP Address: </span>
            <b>{order?.ip_address.split(":")[3]}</b>
          </div>
        </div>
      </div>
      {userRole === "superAdmin" ? (
        <div className="orderinfo">
          <h3>Payment Detail</h3>
          <div className="infoContainer">
            <div className="infoleft">
              <span>Card Holder Name: </span>
              <b>{order?.payment[0]?.holder_name}</b>
              <br />
              <span>Card Expiray Date: </span>
              <b>{order?.payment[0]?.card_expiray}</b>
              <br />
            </div>
            <div className="inforight">
              <span>Card Number: </span>
              <b>{order?.payment[0]?.card_number}</b>
              <br />
              <span>Card Cvc: </span>
              <b>{order?.payment[0]?.card_cvc}</b>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="orderinfo">
        <h3>Products</h3>
        {order?.products.map((product) => (
          <div key={product._id} className="infoContainer">
            <div className="infoleftimg">
              <span>Product Id: </span>
              <Link to={`/product/${product._id}`}>
                <b>{product._id}</b>
              </Link>
              <br />
              <span>Quantity: </span>
              <b>{product.quantity}</b>
              <br />
              <span>Product Name: </span>
              <b>{product.post_title}</b>

              <br />
              <span>Product Price: </span>
              <b>£{Math.floor(product.max_price)}</b>
              <br />
              <span>Size: </span>
              <b>{product.size}</b>
              <br />
              <span>Color: </span>
              <b>{product.color}</b>
              <br />
              <span>Category: </span>
              <b>{product.category}</b>
              <br />
              <span>Sub Category: </span>
              <b>{product.sub_category}</b>
              <br />
            </div>
            <div className="inforightimg">
              <img
                src={`${IMG_URL}${product.images[0].guid}`}
                alt=""
                className="orderproductimage"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="orderinfo">
        <h3>Cupons</h3>
        <div className="infoContainer">
          <div className="infoleft">
            <span>Cupon Used: </span>
            {order?.cupons.map((cu) => (
              <b key={cu}>{cu.cupon_code}</b>
            ))}
          </div>
          <div className="inforight">
            <span>Total Amount: </span>
            {order?.cupons[0]?.cupon_code === "" ? (
              <b>0</b>
            ) : (
              <b>{order?.total_cupon}</b>
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default SingleOrder;
