import React, { useState } from "react";
import { IMG_URL } from "../../requestMethod";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { MailOutline, PermIdentity, PhoneAndroid } from "@material-ui/icons";
import "./newUser.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const SearchUser = () => {
  const history = useHistory();
  const token = useSelector((state) => state.user.currentUser.accessToken);

  const [username, setUserName] = useState("");
  const [userrole, setUserRole] = useState("");
  const [users, setUsers] = useState([]);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);

  useEffect(() => {
    if (
      userRole === "assistant" ||
      userRole === "vendor" ||
      userRole === "developer"
    ) {
      history.push("/");
    }
  }, [userRole, history]);

  const handleUserName = async (e) => {
    e.preventDefault();
    if (username === "") {
      toast.error("Please Enter Username");
    } else {
      const head = { headers: { token: `Bearer ${token}` } };
      axios
        .get(`${IMG_URL}api/users/username/?username=${username}`, head)
        .then((res) => {
          setUsers(res.data.user);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
      // try {
      //   const res = await userRequest.get(
      //     `users/username/?username=${username}`
      //   );
      //   setUsers(res.data.user);
      // } catch (error) {
      //   toast.error("Something Went Wrong");
      // }
    }
  };
  const handleUserId = async (e) => {
    e.preventDefault();
    if (userrole === "") {
      toast.error("Please Enter User Role");
    } else {
      const head = { headers: { token: `Bearer ${token}` } };
      axios
        .get(`${IMG_URL}api/users/role/?role=${userrole}`, head)
        .then((res) => {
          setUsers(res.data.user);
        })
        .catch((err) => {
          toast.error("Something Went Wrong");
        });
      // try {
      //   const res = await userRequest.get(`users/role/?role=${userrole}`);
      //   setUsers(res.data.user);
      // } catch (error) {
      //   toast.error("Something Went Wrong");
      // }
    }
  };
  return (
    <div className="product usercontsearch">
      <div className="searchcontainer">
        <div className="searchproductnamecontainer">
          <label className="searchproductnamelabel">Search By Username</label>
          <br />
          <input
            type="text"
            placeholder="Search User By Username"
            className="searchproductbynameinput"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
          />
          <button className="productNameSearchBtn" onClick={handleUserName}>
            Search
          </button>
        </div>
        <div className="searchproductidcontainer">
          <label className="searchproductnamelabel">Search By User Role</label>
          <br />
          <input
            type="text"
            placeholder="Search User By User Role"
            className="searchproductbynameinput"
            value={userrole}
            onChange={(e) => setUserRole(e.target.value)}
          />
          <button className="productNameSearchBtn" onClick={handleUserId}>
            Search
          </button>
        </div>
      </div>
      {users?.map((user) => {
        return (
          <div className="userSearch" key={user._id}>
            <div className="userSearchTop">
              <img
                src="https://www.focusedu.org/wp-content/uploads/2018/12/circled-user-male-skin-type-1-2.png"
                alt=""
                className="userSearchImg"
              />
              <div className="userSearchTopTitle">
                <span className="userSearchUsername">
                  {user?.first_name} {user?.last_name}
                </span>
                <span className="userSearchUserTitle">
                  {user?.isAdmin ? "Admin" : "User"}
                </span>
              </div>
            </div>
            <div className="userSearchBottom">
              <span className="userSearchUserTitle">Account Details</span>
              <div className="userSearchInfo">
                ID:
                <b>
                  <div className="userSearchInfoTitle">
                    <Link to={`/user/${user?._id}`}>
                      <b>{user._id}</b>
                    </Link>
                  </div>
                </b>
              </div>
              <div className="userSearchInfo">
                Username:
                <PermIdentity className="userSearchIcon" />
                <b>
                  <div className="userSearchInfoTitle">{user?.username}</div>
                </b>
              </div>
              <div className="userSearchInfo">
                First Name:
                <b>
                  <div className="userSearchInfoTitle">{user?.first_name}</div>
                </b>
              </div>
              <div className="userSearchInfo">
                Last Name:
                <b>
                  <div className="userSearchInfoTitle">{user?.last_name}</div>
                </b>
              </div>
              <div className="userSearchInfo">
                Is Admin:
                <b>
                  <div className="userSearchInfoTitle">
                    {user?.isAdmin ? "Yes" : "No"}
                  </div>
                </b>
              </div>
              <div className="userSearchInfo">
                User Role:
                <b>
                  <div className="userSearchInfoTitle">
                    {user?.userTypeRole}
                  </div>
                </b>
              </div>
              <span className="userSearchUserTitle">Contact Details</span>
              <div className="userSearchInfo">
                <PhoneAndroid className="userSearchIcon" />
                <div className="userSearchInfoTitle">{user?.phone}</div>
              </div>
              <div className="userSearchInfo">
                <MailOutline className="userSearchIcon" />
                <div className="userSearchInfoTitle">{user?.email}</div>
              </div>
            </div>
          </div>
        );
      })}
      <ToastContainer />
    </div>
  );
};

export default SearchUser;
