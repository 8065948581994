import React, { useEffect, useState } from "react";
import "./Cupon.css";
import { useHistory, useLocation } from "react-router-dom";
import { publicRequest, userRequest } from "../../requestMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SingleFreeItemCoupon = () => {
  const location = useLocation();
  const cuponId = location.pathname.split("/")[2];
  const [cupon_code, setCuponCode] = useState("");
  const [product_no, setProductNo] = useState("");
  const [free_product_no, setFreeProductNo] = useState("");
  const [from_date, setFromDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [formcuponcode, setFormCuponCode] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [is_active, setisActive] = useState(true);
  const [updateProdNo, setUpdatedProdNo] = useState("");
  const [freeprod, setFreeProduct] = useState("");
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();

  useEffect(() => {
    if (userRole !== "superAdmin") {
      history.push("/");
    }
    const getCupon = async () => {
      const res = await publicRequest.get(`/freeprodcoupon/find/${cuponId}`);
      setCuponCode(res.data?.cupon?.cupon_code);
      const d3 = new Date(res?.data.cupon.from_date).toString("YYYY-MM-dd");
      setStartDate(new Date(d3));
      const d4 = new Date(res?.data.cupon.to_date).toString("YYYY-MM-dd");
      setEndDate(new Date(d4));
      const d1 = new Date(res?.data.cupon.from_date).toDateString();
      setFromDate(d1);
      const d2 = new Date(res?.data.cupon.to_date).toDateString();
      setToDate(d2);
      setFormCuponCode(res.data?.cupon?.cupon_code);
      setProductNo(res.data?.cupon?.buy_no_of_item);
      setFreeProductNo(res.data?.cupon?.free_no_of_item);
      setUpdatedProdNo(res.data?.cupon?.buy_no_of_item);
      setFreeProduct(res.data?.cupon?.free_no_of_item);
      setisActive(res.data?.cupon?.is_active);
    };
    getCupon();
  }, [cuponId, userRole, history]);
  const handleCuponUpdate = async () => {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);

    if (formcuponcode === "") {
      toast.error("Please Enter Cupon Code");
    } else if (parseInt(updateProdNo) < parseInt(freeprod)) {
      toast.error(
        "Free No Of Products Can't Be Greater Than No Of Buy Products."
      );
    } else if (d1 > d2) {
      toast.error("To Date Should Be Greater Than From Date");
    } else {
      try {
        const data = {
          cupon_code: formcuponcode,
          from_date: startDate,
          to_date: endDate,
          buy_no_of_item: updateProdNo,
          free_no_of_item: freeprod,
          is_active: is_active,
        };
        const res = await userRequest.put(`/freeprodcoupon/${cuponId}`, data);
        setCuponCode(res.data?.cupon_code);
        const d3 = new Date(res?.data?.from_date).toString("YYYY-MM-dd");
        setStartDate(new Date(d3));
        const d4 = new Date(res?.data?.to_date).toString("YYYY-MM-dd");
        setEndDate(new Date(d4));
        const d1 = new Date(res?.data?.from_date).toDateString();
        setFromDate(d1);
        const d2 = new Date(res?.data?.to_date).toDateString();
        setToDate(d2);
        setFormCuponCode(res.data?.cupon_code);
        setProductNo(res.data?.buy_no_of_item);
        setUpdatedProdNo(res.data?.buy_no_of_item);
        setFreeProduct(res.data?.free_no_of_item);
        setisActive(res.data?.is_active);
        toast.success("Cupon Updated Successfully");
      } catch (error) {
        toast.error("Something Went Wrong");
      }
    }
  };
  return (
    <>
      <div className="cupon">
        <div className="cuponConatiner">
          <div className="cuponInfo">
            <div className="cuponinfocontainer">
              <h1>Free Item Coupon Details</h1>
              <span>
                Coupon Code: <b>{cupon_code}</b>
              </span>
              <br />
              <span>
                Buy No Of Products: <b>{product_no}</b>
              </span>
              <br />
              <span>
                Free No Of Products: <b>{free_product_no}</b>
              </span>
              <br />
              <span>
                From Date: <b>{from_date}</b>
              </span>
              <br />
              <span>
                To Date: <b>{to_date}</b>
              </span>
              <br />
              <span>
                Is Active?: <b>{is_active ? "Yes" : "No"}</b>
              </span>
            </div>
          </div>
          <div className="cuponForm">
            <div className="cuponformcontainer">
              <h1>Update Coupon</h1>
              <label>Coupon Code</label>
              <input
                type="text"
                placeholder="Cupon Code"
                className="cuponUpdateInput"
                value={formcuponcode}
                onChange={(e) => setFormCuponCode(e.target.value)}
              />
              <br />
              <label>Buy No Of Products</label>
              <input
                type="number"
                placeholder="Buy No Of Products"
                className="cuponUpdateInput"
                value={updateProdNo}
                onChange={(e) => setUpdatedProdNo(e.target.value)}
              />
              <br />
              <label>Free Number Of Products</label>
              <input
                type="number"
                placeholder="Free Number Of Products"
                className="cuponUpdateInput"
                value={freeprod}
                onChange={(e) => setFreeProduct(e.target.value)}
              />
              <br />
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">From Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">To Date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <br />
              <div className="activate-cupon-section">
                <label>Activate Coupon</label>
                <input
                  type="checkbox"
                  placeholder="Applied To No Of Products"
                  className="cuponUpdateCheckbox"
                  checked={is_active}
                  onChange={(e) => setisActive(!is_active)}
                />
              </div>
              <br />
              <button className="cuponUpdateButton" onClick={handleCuponUpdate}>
                Update Coupon
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SingleFreeItemCoupon;
