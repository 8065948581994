import React from "react";
import "./sidebar.css";
import {
  LineStyle,
  PermIdentity,
  Storefront,
  AttachMoney,
  Add,
  Search,
  Receipt,
  LocalOffer,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export const Sidebar = () => {
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  return (
    <>
      <div className="sidebar">
        {/* <h3 className="sidebarTitle">Dashboard</h3> */}
        {userRole === "superAdmin" ? (
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <NavLink
                  exact
                  to="/"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <LineStyle
                      className="sidebaricon"
                      style={{ fontSize: "14px", marginRight: "5px" }}
                    />
                    Home
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">User Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/users"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <PermIdentity className="sidebarIcon" />
                    Users
                  </li>
                </NavLink>
                <NavLink
                  to="/newuser"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Add User
                  </li>
                </NavLink>
                <NavLink
                  to="/searchuser"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Search className="sidebarIcon" />
                    Search Users
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Product Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/products"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Storefront className="sidebarIcon" />
                    Products
                  </li>
                </NavLink>
                <NavLink
                  to="/sale-products"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <LocalOffer className="sidebarIcon" />
                    Sale Products
                  </li>
                </NavLink>
                <NavLink
                  to="/newproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Add Products
                  </li>
                </NavLink>
                <NavLink
                  to="/searchproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Search className="sidebarIcon" />
                    Search Products
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Coupon Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/cupons"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Receipt className="sidebarIcon" />
                    Coupons
                  </li>
                </NavLink>
                <NavLink
                  to="/assigned/cupons"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Receipt className="sidebarIcon" />
                    Assigned Coupons
                  </li>
                </NavLink>
                <NavLink
                  to="/freeitem/cupons"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Receipt className="sidebarIcon" />
                    Free Item Coupons
                  </li>
                </NavLink>
                <NavLink
                  to="/create/cupon"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Create New Coupon
                  </li>
                </NavLink>
                <NavLink
                  to="/create/free-item-coupon"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Create Free Item Coupon
                  </li>
                </NavLink>
                <NavLink
                  to="/create/user/assigned/coupon"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Assign Coupon To User
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Order Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/orders"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <AttachMoney className="sidebarIcon" />
                    Orders
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Blog Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/all-blogs"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">All Blogs</li>
                </NavLink>
                <NavLink
                  to="/create-blog"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">Create Blogs</li>
                </NavLink>
              </ul>
            </div>
          </div>
        ) : userRole === "vendor" ? (
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <NavLink
                  exact
                  to="/"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <LineStyle className="sidebaricon" />
                    Home
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Product Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/products"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Storefront className="sidebarIcon" />
                    Products
                  </li>
                </NavLink>
                <NavLink
                  to="/newproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Add Products
                  </li>
                </NavLink>
                <NavLink
                  to="/searchproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Search className="sidebarIcon" />
                    Search Products
                  </li>
                </NavLink>
              </ul>
            </div>
          </div>
        ) : userRole === "assistant" ? (
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <NavLink
                  exact
                  to="/"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <LineStyle className="sidebaricon" />
                    Home
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Product Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/products"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Storefront className="sidebarIcon" />
                    Products
                  </li>
                </NavLink>
                <NavLink
                  to="/newproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Add Products
                  </li>
                </NavLink>
                <NavLink
                  to="/searchproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Search className="sidebarIcon" />
                    Search Products
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Order Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/orders"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <AttachMoney className="sidebarIcon" />
                    Orders
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Blog Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/all-blogs"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">All Blogs</li>
                </NavLink>
                <NavLink
                  to="/create-blog"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">Create Blogs</li>
                </NavLink>
              </ul>
            </div>
          </div>
        ) : userRole === "developer" ? (
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              <ul className="sidebarList">
                <NavLink
                  exact
                  to="/"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <LineStyle className="sidebaricon" />
                    Home
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Product Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/products"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Storefront className="sidebarIcon" />
                    Products
                  </li>
                </NavLink>
                <NavLink
                  to="/newproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Add className="sidebarIcon" />
                    Add Products
                  </li>
                </NavLink>
                <NavLink
                  to="/searchproduct"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <Search className="sidebarIcon" />
                    Search Products
                  </li>
                </NavLink>
              </ul>
            </div>
            <div className="sidebarMenu">
              <h3 className="sidebarTitle">Order Section</h3>
              <ul className="sidebarList">
                <NavLink
                  to="/orders"
                  className={(isActive) =>
                    isActive
                      ? "link sidebarListItem active"
                      : "link sidebarListItem"
                  }
                >
                  <li className="list-item">
                    <AttachMoney className="sidebarIcon" />
                    Orders
                  </li>
                </NavLink>
              </ul>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
