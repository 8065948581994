import React, { useRef, useState } from "react";
import "./newProduct.css";
import { DropzoneArea } from "material-ui-dropzone";
import { man, women, accessories, shoes, watches, bag, kids } from "./data";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
// import { userRequest } from "../../requestMethod";
import { logout } from "../../redux/userRedux";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
import PreLoader from "../../components/PreLoader/PreLoader";
import axios from "axios";
import { IMG_URL } from "../../requestMethod";
import { Editor } from "@tinymce/tinymce-react";

export const NewProduct = () => {
  const history = useHistory();
  const [photos, setPhotos] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [category, setCategory] = useState("Select Category");
  const [subcategory, setSubCategory] = useState("Select Sub Category");
  const [inStock, setInStock] = useState("Yes");
  const [onSale, setonSale] = useState("No");
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [newDesc, setNewDesc] = useState();
  const [proPrice, setProdPrice] = useState(null);
  const [salePrice, setSalePrice] = useState(null);
  const [StockQuantity, setStockItem] = useState(null);
  const [inputListColor, setInputListColor] = useState([{ color: "" }]);
  const [inputListSize, setInputListSize] = useState([{ size: "" }]);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const log = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      const desContent = editorRef.current.getContent();
      setNewDesc(desContent);
      // console.log(editorRef.current.getContent());
    }
  };
  /* Validate Youtube URL  */
  // function isValidYouTubeUrl(url) {
  //   const youtubeRegex =
  //     /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)(&\S*)?$/;
  //   return youtubeRegex.test(url);
  // }
  const user = useSelector((state) => state.user.currentUser);
  // handle input change
  const handleInputChangeColor = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListColor];
    list[index][name] = value;
    setInputListColor(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickColor = (e, index) => {
    e.preventDefault();
    const list = [...inputListColor];
    list.splice(index, 1);
    setInputListColor(list);
  };

  // handle click event of the Add button
  const handleAddClickColor = () => {
    setInputListColor([...inputListColor, { color: "" }]);
  };

  // handle input change
  const handleInputChangeSize = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputListSize];
    list[index][name] = value;
    setInputListSize(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickSize = (e, index) => {
    e.preventDefault();
    const list = [...inputListSize];
    list.splice(index, 1);
    setInputListSize(list);
  };

  // handle click event of the Add button
  const handleAddClickSize = () => {
    setInputListSize([...inputListSize, { size: "" }]);
  };

  const handlephoto = (fileObject) => {
    setPhotos(fileObject);
  };
  const handleMainImage = (obj) => {
    setMainImage(obj.target.files[0]);
    setMainImagePreview(URL.createObjectURL(obj.target.files[0]));
  };
  const handleClick = (e) => {
    e.preventDefault();
    const color = [];
    inputListColor.map((clr) => {
      return color.push(clr.color);
    });
    const size = [];
    inputListSize.map((siz) => {
      return size.push(siz.size);
    });
    photos.splice(0, 0, mainImage);
    function createProductApiCall() {
      setLoader(true);
      const formData = new FormData();
      formData.append("post_title", productName);
      formData.append("post_name", productDesc);
      formData.append("new_description", newDesc);
      formData.append("youtube_link", youtubeLink);
      formData.append("max_price", proPrice);
      formData.append("sale_price", salePrice);
      formData.append("category", category);
      formData.append("size", size);
      formData.append("color", color);
      formData.append("sub_category", subcategory);
      formData.append("sale_type", onSale);
      formData.append("InStock", inStock);
      formData.append("StockQuantity", StockQuantity);
      formData.append("updateBy", user?.username);
      photos.map((img) => {
        return formData.append("images", img);
      });
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          token: `Bearer ${user.accessToken}`,
        },
      };
      axios
        .post(`${IMG_URL}api/products`, formData, config)
        .then((res) => {
          setLoader(true);
          toast.success("Product Created");
          setTimeout(() => {
            history.push("/");
          }, 1000);
        })
        .catch((err) => {
          setLoader(true);
          toast.error("Something Went wrong");
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(logout());
          }
        });
    }
    if (mainImage === null) {
      toast.error("Please Upload Logo Image");
    } else if (productName === "") {
      toast.error("Please Enter Product Name");
    } else if (proPrice === "") {
      toast.error("Please Enter Product Price");
    } else if (!parseInt(proPrice)) {
      toast.error("Please Enter Product Valid Price");
    } else if (photos.length === 1) {
      toast.error("Please Upload Atleast One Multiple Image");
    } else if (category === "") {
      toast.error("Please Select Product Category");
    } else if (category === "Select Category") {
      toast.error("Please Select Product Category");
    } else if (subcategory === "") {
      toast.error("Please Select Product Sub Category");
    } else if (subcategory === "Select Sub Category") {
      toast.error("Please Select Product Sub Category");
    } else if (onSale === "Yes") {
      if (salePrice === "") {
        toast.error("Please Enter Sale Price");
      } else if (!parseInt(salePrice)) {
        toast.error("Please Enter A Valid Sale Price");
      }
    } else if (color.length === 0) {
      toast.error("Please Enter Product Colors");
    } else if (size.length === 0) {
      toast.error("Please Enter Product Size");
    } else {
      createProductApiCall();
      // if (youtubeLink !== "") {
      //   if (!isValidYouTubeUrl(youtubeLink)) {
      //     toast.error("Please Enter A Valid Youtube Link");
      //   } else {
      //     createProductApiCall();
      //   }
      // }
      // else {
      //   createProductApiCall();
      // }
    }
  };

  const handleFirst = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
    ];

    setInputListColor(dd);
  };
  const handleSecond = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
    ];

    setInputListColor(dd);
  };
  const handleThird = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
      {
        color: "11",
      },
      {
        color: "12",
      },
      {
        color: "13",
      },
      {
        color: "14",
      },
      {
        color: "15",
      },
    ];

    setInputListColor(dd);
  };
  const handleFourth = (e) => {
    e.preventDefault();
    const dd = [
      {
        color: "1",
      },
      {
        color: "2",
      },
      {
        color: "3",
      },
      {
        color: "4",
      },
      {
        color: "5",
      },
      {
        color: "6",
      },
      {
        color: "7",
      },
      {
        color: "8",
      },
      {
        color: "9",
      },
      {
        color: "10",
      },
      {
        color: "11",
      },
      {
        color: "12",
      },
      {
        color: "13",
      },
      {
        color: "14",
      },
      {
        color: "15",
      },
      {
        color: "16",
      },
      {
        color: "17",
      },
      {
        color: "18",
      },
      {
        color: "19",
      },
      {
        color: "20",
      },
    ];

    setInputListColor(dd);
  };
  const handleSXL = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "S",
      },
      {
        size: "M",
      },
      {
        size: "L",
      },
      {
        size: "XL",
      },
      {
        size: "XXL",
      },
    ];

    setInputListSize(dd);
  };
  const handleWomenSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "34",
      },
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
    ];

    setInputListSize(dd);
  };
  const handleMenSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
      {
        size: "45",
      },
    ];
    setInputListSize(dd);
  };
  const handleUnisexSize = (e) => {
    e.preventDefault();
    const dd = [
      {
        size: "34",
      },
      {
        size: "35",
      },
      {
        size: "36",
      },
      {
        size: "37",
      },
      {
        size: "38",
      },
      {
        size: "39",
      },
      {
        size: "40",
      },
      {
        size: "41",
      },
      {
        size: "42",
      },
      {
        size: "43",
      },
      {
        size: "44",
      },
      {
        size: "45",
      },
    ];
    setInputListSize(dd);
  };
  return (
    <>
      {loader ? (
        <PreLoader />
      ) : (
        <div className="newProduct">
          <h1 className="addProductTitle">New Product</h1>
          <form className="addProductForm">
            <div className="addProductItem">
              <label>Cover Image</label>
              <input
                type="file"
                id="file"
                accept="image/*"
                onChange={handleMainImage}
              />
            </div>
            <div className="addProductItem">
              {mainImage ? <img src={mainImagePreview} alt="" /> : <></>}
            </div>
            <div className="addProductItem">
              <label>Product Title</label>
              <input
                type="text"
                placeholder="Product Title"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className="addProductItem">
              <label>Short Description</label>
              <textarea
                type="text"
                placeholder="Product Description"
                value={productDesc}
                onChange={(e) => setProductDesc(e.target.value)}
              />
            </div>
            <div className="addProductItem">
              <label>Youtube Link</label>
              <textarea
                type="text"
                placeholder="Youtube Link"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
            </div>
            <div className="addProductItem">
              <label>Long Description</label>
              <Editor
                apiKey="thv6ktpzfvfxnmwzq7yfz32lim0e2jmq42cpjb6xbosykiug"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={newDesc}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <button onClick={log} className="btn btn-primary mt-3">
                + Add This Description
              </button>
            </div>
            <div className="addProductItem">
              <label>Price</label>
              <input
                type="number"
                placeholder="£1000"
                value={proPrice}
                onChange={(e) => setProdPrice(e.target.value)}
              />
            </div>
            <div className="addProductItem">
              <label>Upload Multiples Images</label>
            </div>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              fileObjects={photos}
              filesLimit={40}
              maxFileSize={5000000}
              onChange={(fileObject) => handlephoto(fileObject)}
            />
            <div className="addProductItem">
              <label>Category</label>
              <select
                name="active"
                id="active"
                defaultValue={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>{category}</option>
                <option value="MEN">MEN</option>
                <option value="WOMEN">WOMEN</option>
                <option value="ACCESSORIES">ACCESSORIES</option>
                <option value="BAGS">BAGS</option>
                <option value="SHOES">SHOES</option>
                <option value="WATCHES">WATCHES</option>
                <option value="KIDS">KIDS</option>
              </select>
              {category === "MEN" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {man.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "WOMEN" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {women.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "ACCESSORIES" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {accessories.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "BAGS" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {bag.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "SHOES" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {shoes.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "WATCHES" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {watches.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : category === "KIDS" ? (
                <>
                  <label>Sub Category</label>
                  <select
                    name="active"
                    id="active"
                    defaultValue={subcategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                  >
                    <option>{subcategory}</option>
                    {kids.map((item) => (
                      <option value={item.navdata} key={item.index}>
                        {item.navdata}
                      </option>
                    ))}
                  </select>
                </>
              ) : (
                <></>
              )}
              <label>InStock</label>
              <select
                name="active"
                id="active"
                defaultValue={inStock}
                onChange={(e) => setInStock(e.target.value)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {inStock === "Yes" && (
                <div className="addProductItem">
                  <label>Items In Stock</label>
                  <input
                    type="number"
                    placeholder="Items In Stock"
                    value={StockQuantity}
                    onChange={(e) => setStockItem(e.target.value)}
                  />
                </div>
              )}
              <label>onSale</label>
              <select
                name="active"
                id="active"
                defaultValue={onSale}
                onChange={(e) => setonSale(e.target.value)}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
              {onSale === "Yes" ? (
                <div className="addProductItem">
                  <label>Sale Price</label>
                  <input
                    type="number"
                    placeholder="Sale Price"
                    value={salePrice}
                    onChange={(e) => setSalePrice(e.target.value)}
                  />
                </div>
              ) : (
                <></>
              )}
              <label>Color</label>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleFirst}
                >
                  1-5
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleSecond}
                >
                  1-10
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleThird}
                >
                  1-15
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleFourth}
                >
                  1-20
                </button>
              </div>
              <div className="Color">
                {inputListColor.map((x, i) => {
                  return (
                    <div className="addProductItem">
                      <input
                        name="color"
                        placeholder="Add New Color"
                        value={x.color}
                        onChange={(e) => handleInputChangeColor(e, i)}
                      />
                      <div className="btn-box">
                        {inputListColor.length !== 1 && (
                          <button
                            className="mr10 btn btn-danger"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            onClick={(e) => handleRemoveClickColor(e, i)}
                          >
                            Remove
                          </button>
                        )}
                        {inputListColor.length - 1 === i && (
                          <button
                            onClick={handleAddClickColor}
                            className="btn btn-success"
                            style={{ marginTop: "10px" }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/* <div style={{ marginTop: 20 }}>
              {JSON.stringify(inputListColor)}
            </div> */}
              </div>
              <label>Size</label>
              <div style={{ display: "flex", marginBottom: "10px" }}>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleSXL}
                >
                  S-XXL
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleWomenSize}
                >
                  34-44
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleMenSize}
                >
                  35-45
                </button>
                <button
                  className="btn btn-outline-primary"
                  style={{ width: "100px", marginLeft: "5px" }}
                  onClick={handleUnisexSize}
                >
                  34-45
                </button>
              </div>
              <div className="Size">
                {inputListSize.map((x, i) => {
                  return (
                    <div className="addProductItem">
                      <input
                        name="size"
                        placeholder="Add New Size"
                        value={x.size}
                        onChange={(e) => handleInputChangeSize(e, i)}
                      />
                      <div className="btn-box">
                        {inputListSize.length !== 1 && (
                          <button
                            className="mr10 btn btn-danger"
                            style={{ marginTop: "10px", marginRight: "10px" }}
                            onClick={(e) => handleRemoveClickSize(e, i)}
                          >
                            Remove
                          </button>
                        )}
                        {inputListSize.length - 1 === i && (
                          <button
                            onClick={handleAddClickSize}
                            className="btn btn-success"
                            style={{ marginTop: "10px" }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
                {/* <div style={{ marginTop: 20 }}>{JSON.stringify(inputListSize)}</div> */}
              </div>
            </div>
            <button className="addProductButton" onClick={handleClick}>
              Create
            </button>
          </form>
          <ToastContainer />
        </div>
      )}
    </>
  );
};
