import React from "react";
// import { InfinitySpin } from "react-loader-spinner";
import "./Pre.css";
import logo from "../../assets/logo.png";
const PreLoader = (props) => {
  return (
    <>
      <div className="text-center mt-5 preloader" hidden={props.hidden}>
        <div className="loader-container">
          {/* <InfinitySpin width="200" color="#EFCA8F" /> */}
          <div className="imag-loader-container">
            {/* <div className="loader"> */}
            <img
              src={logo}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
            {/* </div> */}
          </div>
          <h1 className="font-weight-bold text-white">{props.text}</h1>
        </div>
      </div>
    </>
  );
};

export default PreLoader;
