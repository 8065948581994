import React from "react";
import "./widgetSm.css";
import { Visibility } from "@material-ui/icons";
import { useState } from "react";
import { useEffect } from "react";
import { IMG_URL } from "../../requestMethod";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userRedux";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

export const WidgetSm = () => {
  const [users, setUser] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.currentUser.accessToken);
  const history = useHistory();

  useEffect(() => {
    // const getUsers = async () => {
    //   try {
    //     const res = await userRequest.get("users/?new=true");
    //     setUser(res.data?.user);
    //   } catch (error) {
    //     dispatch(logout());
    //   }
    // };
    // getUsers();
    const head = { headers: { token: `Bearer ${token}` } };
    axios
      .get(`${IMG_URL}api/users/?new=true`, head)
      .then((res) => {
        setUser(res.data?.user);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(logout());
        }
      });
  }, [dispatch, token]);

  return (
    <div className="widgetSm">
      <span className="widgetSmTitle">New Join Members</span>
      <ul className="widgetSmList">
        {users?.map((user) => (
          <li className="widgetSmListItem" key={user?._id}>
            <img
              src={
                user?.img ||
                "https://crowd-literature.eu/wp-content/uploads/2015/01/no-avatar.gif"
              }
              alt=""
              className="widgetSmImg"
            />
            <div className="widgetSmUser">
              <span className="widgetSmUsername">{user?.username}</span>
            </div>
            <button
              className="widgetSmButton"
              onClick={() => history.push(`/user/${user?._id}`)}
            >
              <Visibility className="widgetSmIcon" />
              Display
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
