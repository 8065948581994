// export const women = [
//   { navdata: "COATS", index: 1, address: "Women's Coat" },
//   { navdata: "GILET", index: 2, address: "Women's Gilet" },
//   { navdata: "JACKET", index: 3, address: "Women's Jacket" },
//   { navdata: "BLAZER", index: 4, address: "Women's Blazer" },
//   { navdata: "NACKLACE", index: 5, address: "Women's Necklace" },
//   { navdata: "BRACELET", index: 6, address: "Women's Bracelet" },
//   { navdata: "BAG", index: 7, address: "Women's Bag" },
//   { navdata: "BOOT", index: 8, address: "Women's Boot" },
//   { navdata: "SHOES", index: 9, address: "Women's Shoes" },
//   { navdata: "PLATFORM", index: 10, address: "Women's Platform" },
//   { navdata: "HEEL", index: 11, address: "Women's Hell" },
//   { navdata: "WOMEN'S BOOTS", index: 12, address: "Women's Boot" },
//   { navdata: "WOMEN'S PLATFORM", index: 13, address: "Platform" },
//   { navdata: "SNEAKERS", index: 14, address: "Sneakers" },
//   { navdata: "SANDALS", index: 15, address: "Sandal" },
//   { navdata: "SLIDERS", index: 16, address: "Slider" },
// ];

export const man = [
  { navdata: "MEN'S COATS", index: 1, address: "MEN'S COATS" },
  { navdata: "MEN'S GILET", index: 11, address: "MEN'S GILET" },
  { navdata: "MEN'S JACKETS", index: 2, address: "MEN'S JACKETS" },
  { navdata: "MEN'S HOODIES", index: 3, address: "MEN'S HOODIES" },
  { navdata: "MEN'S SWEATSHIRTS", index: 5, address: "MEN'S SWEATSHIRTS" },
  {
    navdata: "MEN'S PREMIUM GRADE T-SHIRTS",
    index: 6,
    address: "MEN'S PREMIUM GRADE T-SHIRTS",
  },
  { navdata: "MEN'S JUMPER", index: 7, address: "MEN'S JUMPER" },
  { navdata: "MEN'S JOGGERS", index: 8, address: "MEN'S JOGGERS" },
  {
    navdata: "MEN'S CLASSIC T-SHIRT",
    index: 9,
    address: "MEN'S CLASSIC T-SHIRT",
  },
  { navdata: "MEN'S POLO SHIRT", index: 10, address: "MEN'S POLO SHIRT" },
  { navdata: "SHIRTS", index: 4, address: "SHIRTS" },
  { navdata: "MEN'S OVERSHIRTS", index: 14, address: "OVERSHIRTS" },
  { navdata: "SWIM SHORTS", index: 15, address: "SWIM SHORTS" },
  { navdata: "SETS", index: 16, address: "SETS" },
];
export const women = [
  { navdata: "WOMEN'S COATS", index: 1, address: "WOMEN'S COATS" },
  { navdata: "WOMEN'S FASHION", index: 2, address: "WOMEN'S FASHION" },
  { navdata: "WOMEN'S HOODIES", index: 3, address: "WOMEN'S HOODIES" },
  { navdata: "WOMEN'S T-SHIRT", index: 4, address: "WOMEN'S T-SHIRT" },
];
export const kids = [
  { navdata: "KID'S TRACKSUIT", index: 1, address: "KID'S TRACKSUIT" },
];
export const bag = [
  { navdata: "PURSES", index: 1, address: "PURSES" },
  { navdata: "POUCHES & WALLETS", index: 2, address: "POUCHES & WALLETS" },
  { navdata: "PREMIUM GRADE BAGS", index: 3, address: "PREMIUM GRADE BAGS" },
  { navdata: "BACKPACKS", index: 4, address: "BACKPACKS" },
  {
    navdata: "SUITCASES AND TRAVEL",
    index: 5,
    address: "SUITCASES AND TRAVEL",
  },
  { navdata: "A+ GRADE BAGS", index: 6, address: "A+ GRADE BAGS" },
];
export const accessories = [
  { navdata: "BATHROBES", index: 4, address: "BATHROBES" },
  { navdata: "BOXERS", index: 1, address: "BOXERS" },
  { navdata: "BRACELETS", index: 5, address: "BRACELETS" },
  { navdata: "EARRINGS", index: 6, address: "EARRINGS" },
  { navdata: "CAPS", index: 3, address: "CAPS" },
  { navdata: "HATS", index: 7, address: "HATS" },
  { navdata: "NECKLACES", index: 8, address: "NECKLACES" },
  { navdata: "RINGS", index: 9, address: "RINGS" },
  { navdata: "SOCKS", index: 10, address: "SOCKS" },
  { navdata: "SUN GLASSES", index: 11, address: "SUN GLASSES" },
];

export const shoes = [
  { navdata: "WOMEN'S SNEAKERS", index: 1, address: "WOMEN'S SNEAKERS" },
  { navdata: "WOMEN'S BOOTS", index: 2, address: "WOMEN'S BOOTS" },
  { navdata: "WOMEN'S PLATFORM", index: 3, address: "WOMEN'S PLATFORM" },
  { navdata: "WOMEN'S HEELS", index: 4, address: "WOMEN'S HEELS" },
  { navdata: "WOMEN'S SLIDERS", index: 5, address: "WOMEN'S SLIDERS" },
  { navdata: "WOMEN'S SANDALS", index: 6, address: "WOMEN'S SANDALS" },
  { navdata: "MEN'S SNEAKERS", index: 7, address: "MEN'S SNEAKERS" },
  { navdata: "MEN'S SMART SHOES", index: 8, address: "MEN'S SMART SHOES" },
  { navdata: "MEN'S BOOT", index: 4, address: "MEN'S BOOT" },
  { navdata: "MEN'S TRAINER", index: 10, address: "MEN'S TRAINER" },
  { navdata: "MEN'S CASUAL", index: 11, address: "MEN'S CASUAL" },
  { navdata: "UNISEX", index: 9, address: "UNISEX" },
];
export const watches = [
  { navdata: "WOMEN'S WATCHES", index: 1, address: "WOMEN'S WATCHES" },
  { navdata: "MEN'S WATCHES", index: 2, address: "MEN'S WATCHES" },
];
