import React from "react";
import "./topbar.css";
import {
  NotificationsNone,
  Language,
  Settings,
  ExitToApp,
} from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/userRedux";
import MobileSidebar from "../sidebar/MobileSidebar";
export default function Topbar() {
  const admin = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo">Admin Dashboard</span>
          <div className="mobile_navbar">
            <MobileSidebar />
          </div>
        </div>
        <div className="topRight">
          {admin ? (
            <div className="topbarIconContainer">
              <ExitToApp onClick={handleLogout} />
            </div>
          ) : (
            <></>
          )}
          <div className="topbarIconContainer">
            <NotificationsNone />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Language />
            <span className="topIconBadge">2</span>
          </div>
          <div className="topbarIconContainer">
            <Settings />
            <span className="topIconBadge">2</span>
          </div>
          <img
            src="https://images.unsplash.com/photo-1594751543129-6701ad444259?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGFyayUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80"
            alt=""
            className="topAvatar"
          />
        </div>
      </div>
    </div>
  );
}
