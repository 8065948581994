import React, { useState } from "react";
import "./newUser.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userRequest } from "../../requestMethod";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

export const NewUser = () => {
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [userTypeRole, setUserTypeRole] = useState("user");
  const [isAdmin, setIsAdmin] = useState(false);
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();

  useEffect(() => {
    if (
      userRole === "vendor" ||
      userRole === "assistant" ||
      userRole === "developer"
    ) {
      history.push("/");
    }
  }, [history, userRole]);

  const handleCreate = async (e) => {
    e.preventDefault();
    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    // const phone_regex =/^\+(?:[0-9] ?){6,14}[0-9]$/;
    if (first_name === "") {
      toast.error("Please Enter First Name");
    } else if (first_name.length <= 2) {
      toast.error("Please Enter A Valid First Name");
    } else if (last_name === "") {
      toast.error("Please Enter Last Name");
    } else if (last_name.length <= 2) {
      toast.error("Please Enter A Valid Last Name");
    } else if (username === "") {
      toast.error("Please Enter Username");
    } else if (username.length < 5) {
      toast.error("Please Enter A Valid Username");
    } else if (email === "") {
      toast.error("Please Enter Email");
    } else if (!regEx.test(email)) {
      toast.error("Please Enter A Valid Email");
    } else if (phone === "") {
      toast.error("Please Enter Phone Number");
    }
    // else if (phone.length !== 12) {
    //   toast.error("Please Enter A Valid Phone Number Like +440000000000");
    // }
    else if (password === "") {
      toast.error("Please Enter Password");
    } else if (password.length < 8) {
      toast.error("Please Enter Password Of Length 8 Characters");
    } else if (confirm_password === "") {
      toast.error("Please Enter Confirm Password");
    } else if (confirm_password !== password) {
      toast.error("Password & Confirm Password Doesn't Match");
    } else {
      const data = {
        username: username,
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone: phone.toString(),
        password: password,
        isAdmin: isAdmin,
        userTypeRole: userTypeRole,
      };
      try {
        const response = await userRequest.post("users/create-user", data);
        if (response.status === 201 || response.status === 200) {
          toast.success("User Created Successfully");
        }
      } catch (error) {
        toast.error("Username Or Email Or Phone Number Already Exist");
      }
    }
  };
  return (
    <div className="newUser">
      <h1 className="newUserTitle">Add New User</h1>
      <div className="form-container userCard">
        <form className="userForm">
          <div className="userUpdateLeft">
            <h4>Create New User</h4>
            <br />
            <div className="userItem">
              <label>Username</label>
              <input
                type="text"
                placeholder="Username"
                className="userInput"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="userItem">
              <label>First Name</label>
              <input
                type="text"
                placeholder="First Name"
                className="userInput"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="userItem">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Last Name"
                className="userInput"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="userItem">
              <label>Email</label>
              <input
                type="text"
                placeholder="Email"
                className="userInput"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="userItem">
              <label>Phone</label>
              <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
              />
              {/* <input
                type="number"
                placeholder="Phone Number"
                className="userInput"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              /> */}
            </div>
            <div className="userItem">
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                className="userInput"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="userItem">
              <label>Confirm Password</label>
              <input
                type="password"
                placeholder="Confirm Password"
                className="userInput"
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "10px" }}>
              <label>Admin</label>
              <input
                type="checkbox"
                style={{ marginLeft: "20px" }}
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
            </div>
            <div className="userUpdateItem">
              <select
                value={userTypeRole}
                onChange={(e) => {
                  setUserTypeRole(e.target.value);
                  if (e.target.value === "user") {
                    setIsAdmin(false);
                  } else {
                    setIsAdmin(true);
                  }
                }}
              >
                <option>user</option>
                <option>vendor</option>
                <option>assistant</option>
                <option>superAdmin</option>
                <option>developer</option>
              </select>
            </div>
            <button className="userButton" onClick={handleCreate}>
              Create
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
