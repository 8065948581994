import React, { useEffect, useMemo } from "react";
import { Chart } from "../../components/chart/Chart";
// import { FeaturedInfo } from '../../components/featuredInfo/FeaturedInfo';
import "./home.css";
// import { userData } from '../../dummyData';
import { WidgetLg } from "../../components/widgetLg/WidgetLg";
import { WidgetSm } from "../../components/widgetSm/WidgetSm";
import { useState } from "react";
import { IMG_URL } from "../../requestMethod";
import { logout } from "../../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.png";
import axios from "axios";
const Home = () => {
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const token = useSelector((state) => state.user.currentUser.accessToken);
  const [userStats, setUserStats] = useState([]);
  const dispatch = useDispatch();

  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  useEffect(() => {
    // const getStats = async () => {
    //   try {
    //     const res = await userRequest.get("/users/stats");
    //     if (res.status !== 200) {
    //       dispatch(logout());
    //     }
    //     res.data.map((item) =>
    //       setUserStats((prev) => [
    //         ...prev,
    //         { name: MONTHS[item._id - 1], "Active User": item.total },
    //       ])
    //     );
    //   } catch (err) {
    //     console.log(err);
    //     dispatch(logout());
    //   }
    // };
    const head = { headers: { token: `Bearer ${token}` } };
    axios
      .get(`${IMG_URL}api/users/stats`, head)
      .then((res) => {
        res.data.map((item) =>
          setUserStats((prev) => [
            ...prev,
            { name: MONTHS[item._id - 1], "Active User": item.total },
          ])
        );
      })
      .catch((err) => {
        // console.log(err);
        dispatch(logout());
      });
    // getStats();
  }, [MONTHS, dispatch, token]);
  return (
    <>
      {userRole === "vendor" || userRole === "assistant" ? (
        <>
          <div className="home">
            <h1>Welcome To Admin Dashboard Of Fabric Designer Outlet</h1>
            <div className="logocontainer">
              <img src={logo} alt="" className="logo-admin-panel" />
            </div>
          </div>
        </>
      ) : (
        <div className="home">
          <Chart
            data={userStats}
            title="User Analytics"
            grid
            dataKey="Active User"
          />
          <div className="homeWidgets">
            <WidgetSm />
            <WidgetLg />
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
