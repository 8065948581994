import React, { useRef, useState } from "react";
import "./blog.css";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { IMG_URL } from "../../requestMethod";
import { useSelector } from "react-redux";
// import axios from "axios";

const CreateBlog = () => {
  const [title, setTitle] = useState(null);
  const [slug, setSlug] = useState(null);
  const [meta, setMeta] = useState(null);
  const [description, setDescription] = useState();
  const [mainImage, setMainImage] = useState(null);
  const [mainImagePreview, setMainImagePreview] = useState(null);

  const editorRef = useRef(null);

  const user = useSelector((state) => state.user.currentUser);

  const log = (e) => {
    e.preventDefault();
    if (editorRef.current) {
      const desContent = editorRef.current.getContent();
      setDescription(desContent);
      if (title === null || title === "") {
        toast.error("Please Enter Title");
      } else if (slug === null || slug === "") {
        toast.error("Please Enter Enter");
      } else if (desContent === "") {
        toast.error("Please Enter Description");
      } else {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("slug", slug);
        formData.append("meta", meta);
        formData.append("description", desContent);
        formData.append("images", mainImage);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            token: `Bearer ${user.accessToken}`,
          },
        };
        axios
          .post(`${IMG_URL}api/blog`, formData, config)
          .then((res) => {
            toast.success("Blog Created.");
            setTitle("");
            setSlug("");
            setMeta("");
            setMainImage(null);
            setMainImagePreview(null);
            setDescription("");
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
      // console.log(editorRef.current.getContent());
    }
  };
  const handleMainImage = (obj) => {
    setMainImage(obj.target.files[0]);
    setMainImagePreview(URL.createObjectURL(obj.target.files[0]));
  };
  return (
    <div className="blog">
      <div className="addBlog">
        <label>Title</label>
        <input
          type="text"
          placeholder="Blog Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="addBlog">
        <label>Slug</label>
        <input
          type="text"
          placeholder="Blog Slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />
      </div>
      <div className="addBlog">
        <label>Meta</label>
        <input
          type="text"
          placeholder="Blog Meta"
          value={meta}
          onChange={(e) => setMeta(e.target.value)}
        />
      </div>
      <div className="addBlog">
        <label>Cover Image</label>
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={handleMainImage}
        />
      </div>
      <div className="addBlog">
        {mainImage ? <img src={mainImagePreview} alt="" /> : <></>}
      </div>
      <Editor
        apiKey="thv6ktpzfvfxnmwzq7yfz32lim0e2jmq42cpjb6xbosykiug"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={description}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <button className="btn btn-outline-primary mt-4 mb-4" onClick={log}>
        Create Blog
      </button>
      <ToastContainer />
    </div>
  );
};

export default CreateBlog;
