import React, { useState } from "react";
import "./productList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { deleteProduct } from "../../redux/apiCalls";
import { IMG_URL, publicRequest } from "../../requestMethod";

export const SaleProductList = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const getOrders = async () => {
      try {
        const res = await publicRequest.get("products/on-sale/");
        setProducts(res.data);
      } catch {}
    };
    getOrders();
  }, []);
  const handleDelete = (id) => {
    deleteProduct(id, dispatch);
  };
  const columns = [
    // { field: "_id", headerName: "ID", width: 200 },
    {
      field: "product",
      headerName: "Product",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img
              className="productListImg"
              src={`${IMG_URL}${params?.row?.images[0]?.guid}`}
              alt=""
            />
            {params.row.post_title}
          </div>
        );
      },
    },
    { field: "category", headerName: "Category", width: 150 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 120,
    // },
    {
      field: `$max_price`,
      headerName: "Price",
      width: 120,
      renderCell: (params) => {
        return <b>£{Math.floor(params.row.sale_price)}</b>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/product/" + params.row._id}>
              {/* <button className="productListEdit">Edit</button> */}
              <Edit className="productListEdit" />
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      {products?.length === 0 ? (
        <div className="productList">
          <h1>No Products On Sale</h1>
        </div>
      ) : (
        <div className="productList">
          <h1>Sale Products</h1>
          <DataGrid
            rows={products}
            columns={columns}
            getRowId={(row) => row._id}
            disableSelectionOnClick
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
          />
        </div>
      )}
    </>
  );
};
