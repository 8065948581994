import { Sidebar } from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./app.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserList } from "./pages/userList/UserList";
import { User } from "./pages/user/User";
import { NewUser } from "./pages/newUser/NewUser";
import { ProductList } from "./pages/productList/ProductList";
import { Product } from "./pages/product/Product";
import { NewProduct } from "./pages/newProduct/NewProduct";
import Login from "./pages/login/Login";
import { useSelector } from "react-redux";
import OrderList from "./pages/order/OrderList";
import SingleOrder from "./pages/order/SingleOrder";
import SearchProduct from "./pages/product/SearchProduct";
import SearchUser from "./pages/newUser/SearchUser";
import CuponList from "./pages/cupon/CuponList";
import SingleCupon from "./pages/cupon/SingleCupon";
import CreateCupon from "./pages/cupon/CreateCupon";
import CreateUserAssignedCoupon from "./pages/cupon/CreateUserAssignedCoupon";
import AssignedCuponList from "./pages/cupon/AssignedCuponList";
import SingleAssignedCoupon from "./pages/cupon/SingleAssignedCoupon";
import CreateGetFreeCoupon from "./pages/cupon/CreateGetFreeCoupon";
import GetFreeProdCouponList from "./pages/cupon/GetFreeProdCouponList";
import SingleFreeItemCoupon from "./pages/cupon/SingleFreeItemCoupon";
import AllBlogs from "./pages/Blog/AllBlogs";
import CreateBlog from "./pages/Blog/CreateBlog";
import UpdateBlog from "./pages/Blog/UpdateBlog";
import { SaleProductList } from "./pages/productList/SaleProductList";

function App() {
  const admin = useSelector((state) => state.user?.currentUser?.isAdmin);
  return (
    <Router>
      <Switch>
        {/* <Route path="/login">
          <Login />
        </Route> */}
        {admin ? (
          <>
            <Topbar />
            <div className="container">
              <Sidebar />
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/users">
                <UserList />
              </Route>
              <Route path="/user/:userId">
                <User />
              </Route>
              <Route path="/newuser">
                <NewUser />
              </Route>
              <Route path="/searchuser">
                <SearchUser />
              </Route>
              <Route path="/orders">
                <OrderList />
              </Route>
              <Route path="/order/:orderId">
                <SingleOrder />
              </Route>
              <Route path="/create/cupon">
                <CreateCupon />
              </Route>
              <Route path="/create/user/assigned/coupon">
                <CreateUserAssignedCoupon />
              </Route>
              <Route path="/create/free-item-coupon">
                <CreateGetFreeCoupon />
              </Route>
              <Route path="/cupons">
                <CuponList />
              </Route>
              <Route path="/assigned/cupons">
                <AssignedCuponList />
              </Route>
              <Route path="/freeitem/cupons">
                <GetFreeProdCouponList />
              </Route>
              <Route path="/freeitemcupons/:cuponId">
                <SingleFreeItemCoupon />
              </Route>
              <Route path="/assignedcupons/:cuponId">
                <SingleAssignedCoupon />
              </Route>
              <Route path="/cupon/:cuponId">
                <SingleCupon />
              </Route>
              <Route path="/products">
                <ProductList />
              </Route>
              <Route path="/sale-products">
                <SaleProductList />
              </Route>
              <Route path="/product/:productId">
                <Product />
              </Route>
              <Route path="/newproduct">
                <NewProduct />
              </Route>
              <Route path="/searchproduct">
                <SearchProduct />
              </Route>
              <Route path="/all-blogs">
                <AllBlogs />
              </Route>
              <Route path="/create-blog">
                <CreateBlog />
              </Route>
              <Route path="/update-blog/:blogId">
                <UpdateBlog />
              </Route>
            </div>
          </>
        ) : (
          <Route path="/">
            <Login />
          </Route>
        )}
      </Switch>
    </Router>
  );
}

export default App;
