import React, { useState } from "react";
import "./product.css";
import { IMG_URL, publicRequest } from "../../requestMethod";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteProduct } from "../../redux/apiCalls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLoader from "../../components/PreLoader/PreLoader";
import ReactPaginate from "react-paginate";
import {
  man,
  women,
  bag,
  accessories,
  shoes,
  watches,
  kids,
} from "../newProduct/data";

const SearchProduct = () => {
  const dispatch = useDispatch();

  const [productname, setProductName] = useState("");
  const [productId, setProductId] = useState("");
  const [product, setProduct] = useState([]);
  const [showloader, setShowLoader] = useState(false);
  const [allProduct, setAllProduct] = useState(null);
  const [responseText, setResponseText] = useState("");
  const defaultSelectValue = "ALL";
  const [selected, setSelected] = useState(defaultSelectValue);
  const [selectedsubcat, setSelectedSubCat] = useState(defaultSelectValue);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  let all_subCategory = [
    ...man,
    ...women,
    ...accessories,
    ...bag,
    ...shoes,
    ...watches,
    ...kids,
  ];
  const handleProductName = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    const res = await publicRequest.get(
      `products/productname/?productname=${productname}`
    );
    setProduct(res.data);
    setAllProduct(res.data);
    setShowLoader(false);
    if (
      res?.data?.length === 0 ||
      res.data === null ||
      res.data === undefined
    ) {
      setResponseText("No Product Found");
    }
  };
  const handleProductId = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    const res = await publicRequest.get(`products/${productId}`);
    setProduct([res.data]);
    setAllProduct([res.data]);
    setShowLoader(false);
    if (res.status !== 200) {
      setResponseText("No Product Found");
    }
    if (
      res?.data?.length === 0 ||
      res.data === null ||
      res.data === undefined
    ) {
      setResponseText("No Product Found");
    }
  };
  const handleDelete = (id) => {
    deleteProduct(id, dispatch);
    toast.success("Product Deleted Successfully");
    setProduct([]);
    setAllProduct([]);
  };
  const handleCategory = async () => {
    if (category !== null) {
      setShowLoader(true);
      const res = await publicRequest.get(
        `products/category/?category=${category}`
      );
      setProduct(res.data);
      setAllProduct(res.data);
      setShowLoader(false);
      if (
        res?.data?.length === 0 ||
        res.data === null ||
        res.data === undefined
      ) {
        setResponseText("No Product Found");
      }
    }
  };

  const handleSubCategory = async () => {
    if (subCategory !== null) {
      setShowLoader(true);
      const res = await publicRequest.get(
        `products/subcategory/?sub_category=${subCategory}`
      );
      setProduct(res.data);
      setAllProduct(res.data);
      setShowLoader(false);
      if (
        res?.data?.length === 0 ||
        res.data === null ||
        res.data === undefined
      ) {
        setResponseText("No Product Found");
      }
    }
  };
  function Product(prod) {
    return (
      <div className="productcontainer" key={prod?._id}>
        <div className="productcontainerLeft">
          <img
            src={`${IMG_URL}${prod?.item?.images[0]?.guid}`}
            alt="ProdImage"
            className="productImage"
          />
        </div>
        <div className="productcontainerRight">
          <span style={{ color: "black" }}>
            Product Id:
            <Link to={`/product/${prod?.item?._id}`}>
              <b>{prod?.item?._id}</b>
            </Link>
          </span>
          <br />
          <span style={{ color: "black" }}>
            Product Name: <b>{prod?.item?.post_title}</b>
          </span>
          <br />
          <span className="prod-desc" style={{ color: "black" }}>
            Product Description: <b>{prod?.item?.post_name}</b>
          </span>
          <br />
          <span style={{ color: "black" }}>
            Product Price: <b>£{Math.floor(prod?.item?.max_price)}</b>
          </span>
          <br />
          <button
            className="delete-product"
            onClick={() => handleDelete(prod?.item?._id)}
          >
            Delete product
          </button>
        </div>
      </div>
    );
  }
  function Items({ currentItems }) {
    return (
      <>
        <div>
          {currentItems &&
            currentItems.map((item) => (
              <Link
                to={`/product/${item._id}`}
                style={{ textDecoration: "none" }}
                key={item.old_id}
              >
                <Product item={item} key={item.id} />
              </Link>
            ))}
        </div>
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = product?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(product?.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % product?.length;
      // console.log(
      //   `User requested page number ${event.selected}, which is offset ${newOffset}`
      // );
      setItemOffset(newOffset);
    };
    return (
      <>
        {currentItems?.length !== 0 ? (
          <>
            <Items currentItems={currentItems} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div className="product">
      <div className="searchcontainer">
        <div className="searchproductnamecontainer">
          <label className="searchproductnamelabel">
            Search By Product Name
          </label>
          <br />
          <input
            type="text"
            placeholder="Search Product By Product Name"
            className="searchproductbynameinput"
            value={productname}
            onChange={(e) => setProductName(e.target.value)}
          />
          <button className="productNameSearchBtn" onClick={handleProductName}>
            Search
          </button>
        </div>
        <div className="searchproductidcontainer">
          <label className="searchproductnamelabel">Search By Product Id</label>
          <br />
          <input
            type="text"
            placeholder="Search Product By Product Id"
            className="searchproductbynameinput"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
          />
          <button className="productNameSearchBtn" onClick={handleProductId}>
            Search
          </button>
        </div>
      </div>
      <div className="searchcontainer" style={{ marginTop: "-20px" }}>
        <div className="searchproductnamecontainer">
          <span>
            <b>Category:</b>
          </span>
          <select
            style={{ padding: "10px", marginLeft: "10px" }}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            defaultValue={category}
          >
            <option>{selected}</option>
            <option>ALL</option>
            <option>MEN</option>
            <option>WOMEN</option>
            <option>ACCESSORIES</option>
            <option>BAGS</option>
            <option>SHOES</option>
            <option>WATCHES</option>
            <option>KIDS</option>
          </select>
          <button className="productNameSearchBtn" onClick={handleCategory}>
            Search
          </button>
        </div>
        <div className="searchproductnamecontainer">
          <span>
            <b>Subcategoy:</b>
          </span>
          <select
            style={{ padding: "10px", marginLeft: "10px" }}
            onChange={(e) => {
              setSubCategory(e.target.value);
            }}
            defaultValue={subCategory}
          >
            <option selected>All Sub Category</option>
            {all_subCategory &&
              all_subCategory.map((subcat) => (
                <>
                  <option key={subcat.index}>{subcat.address}</option>
                </>
              ))}
          </select>
          <button className="productNameSearchBtn" onClick={handleSubCategory}>
            Search
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "10px",
        }}
      >
        <div>
          <span>
            <b>Category Filter:</b>
          </span>
          <select
            style={{ padding: "10px", marginLeft: "10px" }}
            onChange={(e) => {
              setSelected(e.target.value);
              const cat = e.target.value;
              if (cat) {
                if (cat !== "ALL") {
                  try {
                    const filter_prod = allProduct?.filter(
                      (categ) => categ.category === cat
                    );
                    const uniqueArray = filter_prod.filter((value, index) => {
                      const _value = JSON.stringify(value);
                      return (
                        index ===
                        filter_prod.findIndex((obj) => {
                          return JSON.stringify(obj) === _value;
                        })
                      );
                    });
                    setProduct(uniqueArray);
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  setProduct(allProduct);
                }
              }
            }}
            defaultValue={selected}
          >
            <option>{selected}</option>
            <option>ALL</option>
            <option>MEN</option>
            <option>WOMEN</option>
            <option>ACCESSORIES</option>
            <option>BAGS</option>
            <option>SHOES</option>
            <option>WATCHES</option>
            <option>KIDS</option>
          </select>
        </div>
        <div>
          <span>
            <b>Subcategoy Filter:</b>
          </span>
          <select
            style={{ padding: "10px", marginLeft: "10px" }}
            onChange={(e) => {
              setSelectedSubCat(e.target.value);
              const cat = e.target.value;
              if (cat) {
                if (cat !== "All Sub Category") {
                  try {
                    const filter_prod = allProduct?.filter(
                      (sub) => sub.sub_category === cat
                    );
                    const uniqueArray = filter_prod.filter((value, index) => {
                      const _value = JSON.stringify(value);
                      return (
                        index ===
                        filter_prod.findIndex((obj) => {
                          return JSON.stringify(obj) === _value;
                        })
                      );
                    });
                    setProduct(uniqueArray);
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  setProduct(allProduct);
                }
              }
            }}
            defaultValue={selectedsubcat}
          >
            <option selected>All Sub Category</option>
            {selected === "MEN" ? (
              man.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : selected === "WOMEN" ? (
              women.map((subcat) => (
                <>
                  <option key={subcat.index}>{subcat.address}</option>
                </>
              ))
            ) : selected === "ACCESSORIES" ? (
              accessories.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : selected === "BAGS" ? (
              bag.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : selected === "SHOES" ? (
              shoes.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : selected === "WATCHES" ? (
              watches.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : selected === "KIDS" ? (
              kids.map((subcat) => (
                <option key={subcat.index}>{subcat.address}</option>
              ))
            ) : (
              <></>
            )}
          </select>
        </div>
      </div>
      {showloader ? <PreLoader /> : <></>}
      {product.length === 0 ? (
        <h3 className="response-text">{responseText}</h3>
      ) : (
        <PaginatedItems itemsPerPage={12} />
      )}
      <ToastContainer />
    </div>
  );
};

export default SearchProduct;
