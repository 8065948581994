import React, { useEffect, useState } from "react";
import "./Cupon.css";
import { userRequest } from "../../requestMethod";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateUserAssignedCoupon = () => {
  const [cupon_code, setCuponCode] = useState("");
  const [cupon_amount, setCuponAmount] = useState("");
  const [product_no, setProductNum] = useState("");
  const [cupon_username, setCuponUsername] = useState("");
  const [usage_number, setUsageNumber] = useState("");
  const [is_active, setisActive] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const userRole = useSelector((state) => state.user.currentUser.userTypeRole);
  const history = useHistory();
  useEffect(() => {
    if (userRole !== "superAdmin") {
      history.push("/");
    }
  }, [history, userRole]);
  const handleCuponCreate = async () => {
    const d1 = new Date(startDate);
    const d2 = new Date(endDate);
    if (cupon_code === "") {
      toast.error("Please Enter Cupon Code");
    } else if (cupon_amount === "") {
      toast.error("Please Enter Cupon Off Amount");
    } else if (!parseInt(cupon_amount)) {
      toast.error("Please Enter A valid Cupon Off Amount");
    } else if (product_no === "") {
      toast.error("Please Enter The No Of Products");
    } else if (d1 > d2) {
      toast.error("To Date Should Be Greater Than From Date");
    } else {
      try {
        const data = {
          cupon_code: cupon_code,
          cupon_amount: cupon_amount,
          username: cupon_username,
          from_date: startDate,
          to_date: endDate,
          product_no: product_no,
          is_active: is_active,
          total_usage_number: usage_number,
        };
        await userRequest.post(`/supercupon/`, data);
        toast.success("Cupon Created Successfully");
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.error);
      }
    }
  };
  return (
    <>
      <div className="cupon">
        <div className="cuponConatiner">
          <div className="cuponForm">
            <div className="cuponformcontainer">
              <h1>Create Coupon And Assigned To User</h1>
              <label>Coupon Code</label>
              <input
                type="text"
                placeholder="Cupon Code"
                className="cuponUpdateInput"
                value={cupon_code}
                onChange={(e) => setCuponCode(e.target.value)}
              />
              <br />
              <label>Off Amount In £</label>
              <input
                type="number"
                placeholder="Off Amount In £"
                className="cuponUpdateInput"
                value={cupon_amount}
                onChange={(e) => setCuponAmount(e.target.value)}
              />
              <br />
              <label>Username To Assign</label>
              <input
                type="text"
                placeholder="Username"
                className="cuponUpdateInput"
                value={cupon_username}
                onChange={(e) => setCuponUsername(e.target.value)}
              />
              <br />
              <label>No Of Products</label>
              <input
                type="number"
                placeholder="Applied To No Of Products"
                className="cuponUpdateInput"
                value={product_no}
                onChange={(e) => setProductNum(e.target.value)}
              />
              <br />
              <label>Usage Number</label>
              <input
                type="number"
                placeholder="How Many Time Can Use"
                className="cuponUpdateInput"
                value={usage_number}
                onChange={(e) => setUsageNumber(e.target.value)}
              />
              <br />
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">From Date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="outer-container-date">
                <div className="date-container">
                  <label className="date-picker-label">To Date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="from-date"
                    minDate={new Date()}
                  />
                </div>
              </div>
              <br />
              <div className="activate-cupon-section">
                <label>Activate Coupon</label>
                <input
                  type="checkbox"
                  placeholder="Applied To No Of Products"
                  className="cuponUpdateCheckbox"
                  checked={is_active}
                  onChange={(e) => setisActive(!is_active)}
                />
              </div>
              <br />
              <button
                className="cuponUpdateButton"
                onClick={handleCuponCreate}
                showTimeSelect
              >
                Create Coupon
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CreateUserAssignedCoupon;
